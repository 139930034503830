import React from 'react';
import Form from 'react-bootstrap/Form';
import { getIn } from 'formik';
import Input from '../Input';
import styles from './ValidateFormInputField.module.scss';
import { has, toLower } from 'lodash';
import { capitalizeName } from '../../components/CompanyAdmin/frontend-common/utils/Utilities';

export default React.forwardRef(
  (
    {
      field,
      form,
      label,
      regex,
      max,
      handleChange,
      fieldWithoutSpace,
      styleNeeded = true, // Since from starting margin has been added which is not wanted at some places
      showError = true,
      ...props
    },
    ref
  ) => {
    // Handle Blur to remove extra space from the field value
    const handleBlur = (event) => {
      let value = has(props, 'noAutoTrimmedRequired')
        ? event.target.value
        : event.target.value.replace(/\s\s+/g, ' ');

      if (!has(props, 'noAutoTrimmedRequired')) value = value.trim();

      if (has(props, 'capitalizeName')) value = capitalizeName(value);

      if (has(props, 'emailCase')) value = toLower(value);

      form.handleBlur(event);
      form.setFieldValue(field.name, value);
    };

    const handleKeyPress = (e) => {
      // Prevent the entry of the "-" (minus) character
      if (
        has(props, 'isHyphenNotRequired') &&
        (e.key === 'ArrowDown' || e.key === '-' || e.key === 'e' || e.key === 'E')
      ) {
        e.preventDefault();
      }
    };

    return (
      <Form.Group
        style={{ width: '100%' }}
        controlId={field && field.name}
        className={props.className}
      >
        {label && <Form.Label>{label}</Form.Label>}
        <Input
          data-testid='input'
          {...field}
          {...props}
          isInvalid={
            form &&
            getIn(form.errors, field.name) &&
            (getIn(form.touched, field.name) || has(props, 'isTouchedNotRequired'))
              ? true
              : false
          }
          onChange={(event) => {
            if (props.onChange) {
              props.onChange(event);
            } else {
              form.setFieldValue(field.name, event.target.value.replace(regex, '').slice(0, max));
              handleChange && handleChange(event.target.value.replace(regex, '').slice(0, max));
            }
          }}
          ref={ref}
          onBlur={(event) => {
            if (props.onBlur) {
              props.onBlur(event);
            } else {
              handleBlur(event);
            }
          }}
          onKeyDown={handleKeyPress}
        />
        {showError && (
          <div className={styles.inputError}>
            {(form &&
              getIn(form.errors, field.name) &&
              (getIn(form.touched, field.name) || has(props, 'isTouchedNotRequired'))) ||
            props?.errors
              ? (form && getIn(form.errors, field.name)) || props?.errors
              : null}
          </div>
        )}
      </Form.Group>
    );
  }
);
