import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
import moment from 'moment';
import { has } from 'lodash';

import NoRecords from '../NoRecords';
import Image from '../../../../core-components/Image';
import AppConstants from '../../../../core-components/AppConstants';
import { CHECK_STATUSES } from '../../../../utils';
import localStorage from '../../../../api/localStorage';
import { AddressObject } from '../../../../utils/commonConstant';

import styles from './VerificationDetails.module.scss';
import {
  getCityValue as removeAsterisks,
  getStatus,
  removeAsterisksFromString,
  tatDataFormatted
} from '../../../../utils/utilities';
import { capitalizeCase } from '../../frontend-common/utils/Utilities';
import { connect } from 'react-redux';
import { capitalizeName } from '../../frontend-common/utils/Utilities';
class VerificationDetails extends React.Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      IDENTITY_TYPE: {
        1: 'Any',
        2: 'PAN',
        3: 'Driving License',
        4: 'Passport',
        5: 'Voter ID',
        6: 'Aadhaar'
      },
      type: [],
      loading: true,
      title: null,
      document: [],
      i: 0,
      j: 0,
      docUrl: '',
      docxUrl: '',
      companyId: this?.props?.profile?.company_user_mapping?.company_id,
      docsTotalPage: new Map()
    };
  }
  token = localStorage.getToken();
  baseUrl = AppConstants.developmentAPIURL;

  validateNumber = (number) => {
    return number !== null && number !== undefined && number !== '' && number !== 0;
  };

  validateString = (string) => {
    return string !== null && string !== undefined && string !== '';
  };

  downloadDocFile = () => {
    return (
      <a href={this.state.docUrl} download={'document.doc'}>
        View document
      </a>
    );
  };

  downloadDocxFile = () => {
    return (
      <a href={this.state.docxUrl} download={'document.docx'}>
        View document
      </a>
    );
  };

  fetchContentType = (title, doc) => {
    if (this.state.title !== title) {
      this.setState({ type: [], document: [], loading: true });
      this.state.i++;
      axios.get(doc, { responseType: 'blob' }).then((response) => {
        if (response.headers['content-type'] === 'application/msword') {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.setState({
            docUrl: url
          });
        } else if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.setState({
            docxUrl: url
          });
        }
        this.state.document.push(doc);
        this.state.type.push(response.headers['content-type']);
        this.state.j++;

        if (this.state.i == this.state.j) {
          this.setState({
            loading: false
          });
        }
      });

      this.setState({
        title: title
      });
    }
  };

  // creating Map for dcouments with key pair as [url,count of pages in doc]
  setPages = (url, count) => {
    this.setState({
      ...this.state,
      docsTotalPage: this.state.docsTotalPage.set(url, count)
    });
  };

  // this funtion return sthe count for the uploaded PDF's from map created in state
  getCount = (url) => {
    return this.state.docsTotalPage.get(url);
  };
  renderPdfToImg = (url, doc) => {
    const checkType = url.substring(url.indexOf('checkType=') + 10, url.lastIndexOf('&'));
    return (
      <a
        href={`${AppConstants.baseURL}candidate/document?uuid=${doc.uuid}&checkType=${checkType}`}
        target={'_blank'}
        rel='noreferrer'
      >
        <div className={`${styles.viewPdfWrapper} ${this.getCount(url) > 1 ? styles.docs : ''}`}>
          <Document
            file={this.baseUrl.substring(0, this.baseUrl.length - 1) + url + this.token}
            onLoadSuccess={({ numPages }) => {
              this.setPages(url, numPages);
            }}
          >
            {Array.apply(null, Array(this.getCount(url)))
              .map((x, i) => i + 1)
              .map((page) => {
                return <Page pageNumber={page} scale={0.5} />;
              })}
          </Document>
        </div>
      </a>
    );
  };

  renderImg = (doc, addressFile) => {
    const checkType = doc.documentUrl.substring(
      doc.documentUrl.indexOf('checkType=') + 10,
      doc.documentUrl.lastIndexOf('&')
    );
    const cibilCheckType = doc?.checkType ? 'candidates_ids' : 'candidates_creditchecks';

    return (
      <a
        href={
          this?.props?.title.includes('CIBIL')
            ? `${AppConstants.baseURL}candidate/document?uuid=${doc.uuid}&checkType=${cibilCheckType}`
            : addressFile === true && this?.props?.title === 'Court'
            ? `${AppConstants.baseURL}candidate/document?uuid=${doc.uuid}&checkType=candidates_addresses`
            : `${AppConstants.baseURL}candidate/document?uuid=${doc.uuid}&checkType=${checkType}`
        }
        target={'_blank'}
        rel='noreferrer'
      >
        <img
          src={
            addressFile === true || (this?.props?.title.includes('CIBIL') && doc?.checkType)
              ? doc.documentUrl
              : this.baseUrl.substring(0, this.baseUrl.length - 1) + doc.documentUrl + this.token
          }
          alt=''
        />
      </a>
    );
  };

  renderDocuments = (docType, doc, url, addressFile = false) => {
    switch (docType) {
      case 'application/pdf':
        return this.renderPdfToImg(url, doc);
      case 'application/msword':
        return this.downloadDocFile();
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return this.downloadDocxFile();
      default:
        return this.renderImg(doc, addressFile);
    }
  };

  addAddressVerificationType = (addressType) => {
    const combinedAddressChecks = [
      ...this.props?.packageDetails?.checksIncluded?.address,
      ...this.props?.packageDetails?.addons?.address
    ];
    const addressChecksArray = Object.values(combinedAddressChecks);
    const matchingCheck = addressChecksArray.find((check) => {
      return check.check || check.type === AddressObject[addressType];
    });
    if (matchingCheck) {
      return `(${capitalizeCase(matchingCheck.verificationType)})`;
    }
    return '';
  };

  renderTitle = (title, value, voterAddress) => {
    switch (title) {
      case 'Identity':
        return this.state.IDENTITY_TYPE[value.type];
      case 'Employment':
        return (
          <div>
            {value.companyName}
            <div className={styles.duration}>
              {value.startDate ? `${moment(value.startDate).format('MMM YYYY')}` : <div></div>}
              {value.endDate ? (
                value.startDate ? (
                  ` - ${moment(value.endDate).format('MMM YYYY')}`
                ) : (
                  `Till ${moment(value.endDate).format('MMM YYYY')}`
                )
              ) : value.currentlyHere ? (
                ' - Currently working here'
              ) : (
                <div></div>
              )}
            </div>
          </div>
        );
      case 'World':
        return capitalizeName(value.candidateName);
      case 'Drug':
        return value.panelType;
      case 'Reference':
        return value.verifierName;
      case 'CIBIL Credit Score':
        return value.name;
      case 'Court':
        return voterAddress.type;
      default:
        return '';
    }
  };

  render() {
    return (
      <div className={styles.verificationDetailsContainer}>
        <div className={styles.verificationDetailsHeading}>
          <Image name={this.props?.icon} />
          {this.props?.title}
        </div>
        {this.props?.verificationInfo?.length === 0 ? (
          <NoRecords />
        ) : (
          <div className={styles.verificationDetailsContent}>
            {this.props?.verificationInfo?.map((value, index) => {
              let voterAddress = {};
              let { status } = value;
              let courtDocuments = [];
              let cibilDocuments = [];
              if (this.props.title === 'Court') {
                courtDocuments.push(...value?.candidatesAddress?.files, ...value?.files);
              }
              if (this.props.title.includes('CIBIL')) {
                value?.candidatesId
                  ? cibilDocuments.push(
                      ...value?.candidatesId?.files?.map((file) => ({
                        ...file,
                        checkType: 'candidates_ids'
                      })),
                      ...value?.files
                    )
                  : cibilDocuments.push(...value?.files);
              }

              status = getStatus(status);
              this.props?.additionalInfo?.map((val, i) => {
                if (value.addressId === val.id) {
                  voterAddress = {
                    type: val?.documentType,
                    address: val?.address1,
                    residenceType: val?.type,
                    document: val?.files && val?.files?.length !== 0 ? val?.files : ''
                  };
                }
              });
              if (status !== 11) {
                return (
                  <div>
                    {index !== 0 ? <hr /> : <div></div>}
                    <div className={styles.verificationCard}>
                      <div className={styles.cardHeader}>
                        {this.renderTitle(this.props.title, value, voterAddress)}
                        {value.type === 'permanent_address' || value.type === 'Permanent Address'
                          ? `Permanent Address ${this.addAddressVerificationType(value.type)} ${
                              has(value, 'isCurrentPermanentSame') && value.isCurrentPermanentSame
                                ? ' - Marked same as current address'
                                : ''
                            }`
                          : value.type === 'current_address' || value.type === 'Current Address'
                          ? `Current Address ${this.addAddressVerificationType(value.type)}`
                          : ''}
                      </div>

                      <div
                        className={styles.cardStatus}
                        style={{ color: CHECK_STATUSES.values[status].color }}
                      >
                        <svg
                          width='8'
                          height='8'
                          viewBox='0 0 8 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx='4' cy='4' r='4' fill={CHECK_STATUSES.values[status].color} />
                        </svg>
                        {CHECK_STATUSES.values[status].value}
                      </div>
                      <div className={styles.cardContentWrapper}>
                        {this.props.title === 'Identity' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'ID Number'}</div>
                            <div className={styles.value}>
                              {this.validateString(value.identityNumber)
                                ? value.identityNumber
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'World' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Father’s Name'}</div>
                            <div className={styles.value}>
                              {this.validateString(value.fatherName)
                                ? capitalizeName(value.fatherName)
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'CIBIL Credit Score' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'PAN'}</div>
                            <div className={styles.value}>
                              {this.validateString(value?.candidatesId?.identityNumber)
                                ? value.candidatesId.identityNumber
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'Identity' ||
                        this.props.title === 'World' ||
                        this.props.title === 'CIBIL Credit Score' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Date Of Birth'}</div>
                            <div className={styles.value}>
                              {this.validateString(
                                this.props.title === 'CIBIL Credit Score'
                                  ? value?.candidatesId?.dob
                                  : value.dob
                              )
                                ? moment(
                                    this.props.title === 'CIBIL Credit Score'
                                      ? value?.candidatesId?.dob
                                      : value.dob
                                  ).format('DD MMM YYYY')
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'Address' ||
                        this.props.title === 'Court Record' ||
                        this.props.title === 'World' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Address'}</div>
                            <div className={styles.value}>
                              {this.props.title === 'Address'
                                ? this.validateString(value.address1)
                                  ? value.address1 +
                                    ', ' +
                                    value.address2 +
                                    ', ' +
                                    capitalizeName(removeAsterisks(value?.city)) +
                                    ', ' +
                                    removeAsterisks(value?.state) +
                                    ', ' +
                                    removeAsterisks(value?.country) +
                                    ',' +
                                    value.zipcode
                                  : '-'
                                : this.props.title === 'Court Record'
                                ? this.validateString(voterAddress.address)
                                  ? voterAddress.address
                                  : '-'
                                : this.validateString(value.address)
                                ? capitalizeName(removeAsterisksFromString(value.address))
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'Drug' ? (
                          <div>
                            <div className={styles.cardContent}>
                              <div className={styles.label}>{'Sample from'}</div>
                              <div className={styles.value}>
                                {this.validateString(value.candidateName)
                                  ? capitalizeName(value.candidateName)
                                  : '-'}
                              </div>
                            </div>
                            <div className={styles.cardContent}>
                              <div className={styles.label}>{'Verification type'}</div>
                              <div className={styles.value}>
                                {this.validateString(value.verificationType)
                                  ? capitalizeName(value.verificationType)
                                  : '-'}
                              </div>
                            </div>
                            <div className={styles.cardContent}>
                              <div className={styles.label}>{'Sample type'}</div>
                              <div className={styles.value}>
                                {this.validateString(value.sampleType)
                                  ? capitalizeName(value.sampleType)
                                  : '-'}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'Reference' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Company'}</div>
                            <div className={styles.value}>
                              {this.validateString(value.employerName) ? value.employerName : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div className={styles.cardContent}>
                          <div className={styles.label}>{'Insufficiency Time'}</div>
                          <div className={styles.value}>
                            {this.validateNumber(
                              tatDataFormatted(
                                this.props.tatData,
                                this.props.verificationInfo,
                                'checkInsuffNet',
                                index
                              )
                            )
                              ? `${tatDataFormatted(
                                  this.props.tatData,
                                  this.props.verificationInfo,
                                  'checkInsuffNet',
                                  index
                                )} days`
                              : '-'}
                          </div>
                        </div>
                        <div className={styles.cardContent}>
                          <div className={styles.label}>{'On Hold'}</div>
                          <div className={styles.value}>
                            {value?.holdEndDate
                              ? `${moment(value?.holdEndDate).format('YYYY-MM-DD')}`
                              : '-'}
                          </div>
                        </div>
                        <div className={styles.cardContent}>
                          <div className={styles.label}>{'Effective TAT'}</div>
                          <div className={styles.value}>
                            {this.validateNumber(
                              tatDataFormatted(
                                this.props.tatData,
                                this.props.verificationInfo,
                                'actualNetTatNet',
                                index
                              )
                            )
                              ? `${tatDataFormatted(
                                  this.props.tatData,
                                  this.props.verificationInfo,
                                  'actualNetTatNet',
                                  index
                                )} days`
                              : '-'}
                          </div>
                        </div>
                        <div className={styles.cardContent}>
                          <div className={styles.label}>{'Documents'}</div>
                          {value?.files?.length !== 0 ||
                          courtDocuments?.length !== 0 ||
                          cibilDocuments?.length !== 0 ? (
                            <div className={styles.documents}>
                              {this.state.loading ? 'Loading...' : null}
                              {this?.props?.title === 'Court'
                                ? courtDocuments.map((doc, i) => {
                                    let url = doc?.isVerified
                                      ? doc.documentUrl
                                      : `/auth/image?uuid=${doc.uuid}&companyId=${this.state.companyId}&checkType=candidates_addresses&token=`;
                                    let addessFile = doc?.isVerified ? false : true;
                                    return (
                                      <div className={styles.docValue} key={i}>
                                        {url &&
                                          this.fetchContentType(
                                            this.props.title,
                                            this.baseUrl.substring(0, this.baseUrl.length - 1) +
                                              url +
                                              this.token
                                          )}
                                        {this.state.loading ? null : (
                                          <div>
                                            {this.renderDocuments(
                                              this.state.type[
                                                this.state.document.findIndex((document) =>
                                                  document.includes(url)
                                                )
                                              ],
                                              doc,
                                              url,
                                              addessFile
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })
                                : this?.props?.title.includes('CIBIL')
                                ? cibilDocuments.map((doc, i) => {
                                    let url = doc?.checkType
                                      ? `/auth/image?uuid=${doc.uuid}&companyId=${this.state.companyId}&checkType=candidates_ids&token=`
                                      : `/auth/image?uuid=${doc.uuid}&companyId=${this.state.companyId}&checkType=candidates_creditchecks&token=`;
                                    return (
                                      <div className={styles.docValue} key={i}>
                                        {url &&
                                          this.fetchContentType(
                                            this.props.title,
                                            this.baseUrl.substring(0, this.baseUrl.length - 1) +
                                              url +
                                              this.token
                                          )}
                                        {this.state.loading ? null : (
                                          <div>
                                            {this.renderDocuments(
                                              this.state.type[
                                                this.state.document.findIndex((document) =>
                                                  document.includes(url)
                                                )
                                              ],
                                              doc,
                                              url
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })
                                : value.files.map((doc, i) => {
                                    let url = doc.documentUrl;
                                    return (
                                      <div className={styles.docValue} key={i}>
                                        {url &&
                                          this.fetchContentType(
                                            this.props.title,
                                            this.baseUrl.substring(0, this.baseUrl.length - 1) +
                                              url +
                                              this.token
                                          )}
                                        {this.state.loading ? null : (
                                          <div>
                                            {this.renderDocuments(
                                              this.state.type[
                                                this.state.document.findIndex((document) =>
                                                  document.includes(url)
                                                )
                                              ],
                                              doc,
                                              url
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                            </div>
                          ) : (
                            <div className={styles.value}>{'-'}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { profile } = state.profile;
  return { profile };
}

export default connect(mapStateToProps)(VerificationDetails);
