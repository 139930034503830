// React and libraries imports
import React, { useState, useEffect } from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { isEmpty, capitalize, has, isEqual } from 'lodash';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Field } from 'formik';
import Select from 'react-select';

// Core components import
import ValidatedFormInputField from '../../../../../../../core-components/ValidatedFormInputField';
import Button from '../../../../../../../core-components/Button';
import { COUNTRY_CODES } from '../../../../../../../utils/commonConstant';
import { getTrimmedValue } from '../../../../../../../utils/utilities';

// Validations import
import {
  PINCODE_RULES_FOR_INDIA,
  PINCODE_RULES_FOR_OTHER_COUNTRIES_THAN_INDIA
} from './AddressStep1.validation';
import { capitalizeName } from '../../../../../../CompanyAdmin/frontend-common/utils/Utilities';

// Styles import
import styles from './AddressStep1.module.scss';

// Js libraries import
const stateAndCity = require('country-state-city');
const lookup = require('country-code-lookup');

const getNames = (arr) => {
  return arr.map((obj) => obj.name);
};

const getCityValue = (data) => {
  if (!isEmpty(data) && data.endsWith('*')) {
    return data.slice(0, -1);
  }
  return data;
};

export default (props) => {
  const {
    setFieldValue,
    values,
    getGeocodeAddress,
    invalidateGeocodeAddress,
    show,
    errors,
    geoCodeAddressData,
    cachedData,
    index,
    isSubmitting
  } = props;

  const [country, SetCountry] = useState('');
  const [region, setRegion] = useState('');
  const [isCityTextField, setIsCityTextField] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);

  const addressTypeValue = ['Permanent Address', 'Current Address'];

  // Populate state options based on country selected.
  useEffect(() => {
    const country = props?.values?.country || '';
    const state = props?.values?.state || '';

    if (country && state) {
      const countryDetails = lookup.byCountry(country);
      let countryCode;

      if (countryDetails) {
        countryCode = countryDetails.iso2;
      } else {
        countryCode = COUNTRY_CODES[country];
      }

      // Get list of all states of the country.
      const allStates = stateAndCity.State.getStatesOfCountry(countryCode);

      const stateData = allStates.filter((object) => object.name === state)[0];
      let stateCode = '';

      if (stateData) {
        stateCode = stateData.isoCode;
      } else {
        console.error('Could not find the state in the list.');
      }

      const val = getNames(stateAndCity.City.getCitiesOfState(countryCode, stateCode)) || [];

      const cityOptions = val.map((city) => ({
        label: city,
        value: city
      }));

      setCities(cityOptions);
    }
  }, [props.values]);

  useEffect(() => {
    const city = getCityValue(values?.city || '');

    if (!isEmpty(city)) {
      // Prefill values

      if (isEmpty(cities)) {
        // Waiting for city options
        setIsCityTextField(true);
      } else {
        // Check for drop-down values
        if (checkCityList(city)) {
          if (!isEqual(city, selectedCity?.value)) setSelectedCity({ label: city, value: city });
          setIsCityTextField(false);
        } else {
          setSelectedCity(null);
          setIsCityTextField(true);
        }
      }
    } else {
      setIsCityTextField(false);
    }
  }, [props.values, cities]);

  useEffect(() => {
    if (
      !isSubmitting &&
      (isEmpty(geoCodeAddressData) ||
        (!isEmpty(geoCodeAddressData) && !geoCodeAddressData?.loading))
    ) {
      let addressValue = {};
      if (
        has(cachedData.cacheData, 'addressCheck') &&
        !isEmpty(cachedData.cacheData.addressCheck) &&
        cachedData.cacheData.addressCheck.length > index &&
        !isEmpty(cachedData.cacheData.addressCheck[index]) &&
        has(cachedData.cacheData.addressCheck[index], 'step1')
      ) {
        addressValue = props.cachedData.cacheData.addressCheck[props.index]['step1'];
      }
      if (!isEmpty(addressValue)) {
        selectRegion(addressValue?.state);
      } else {
        selectRegion('');
      }
      if (!isEmpty(addressValue)) {
        setFieldValue('city', addressValue?.city);
      } else {
        setFieldValue('city', '');
      }
    }
  }, [cachedData]);

  useEffect(() => {
    invalidateGeocodeAddress();

    return () => {
      // Clear states
      setSelectedCity(null);
    };
  }, []);

  useEffect(() => {
    if (
      show &&
      !isEmpty(geoCodeAddressData) &&
      !geoCodeAddressData.loading &&
      geoCodeAddressData.address.length > 0
    ) {
      const state = geoCodeAddressData.address[0].address_components.filter((add) =>
        add.types.includes('administrative_area_level_1')
      );
      let city = geoCodeAddressData.address[0].address_components.filter((add) =>
        add.types.includes('locality')
      );
      if (isEmpty(city)) {
        city = geoCodeAddressData.address[0].address_components.filter((add) =>
          add.types.includes('administrative_area_level_2')
        );
      }
      if (!isEmpty(state)) {
        selectRegion(state[0]?.long_name);
      } else {
        selectRegion('');
      }
      if (!isEmpty(city)) {
        setFieldValue('city', city[0]?.long_name);
      } else {
        setFieldValue('city', '');
        setSelectedCity(null);
      }
    }
  }, [show, geoCodeAddressData]);

  const selectCountry = (val) => {
    SetCountry(val);
    setFieldValue('country', val);
    selectRegion('');
    setFieldValue('pincode', '');
    setFieldValue('city', '');
    setSelectedCity(null);
  };

  const handlePincodeChange = (e) => {
    let pincodeRegex = new RegExp(PINCODE_RULES_FOR_INDIA);

    if (values.country) {
      if (values.country !== 'India') {
        pincodeRegex = new RegExp(PINCODE_RULES_FOR_OTHER_COUNTRIES_THAN_INDIA);
      }
      if (pincodeRegex.test(e)) {
        getGeocodeAddress({ postalCode: e, country: values.country });
      }
    }

    if (isEmpty(e)) {
      setFieldValue('city', '');
      setSelectedCity(null);
      setFieldValue('state', '');
      setRegion('');
    }
  };

  const selectRegion = (val) => {
    setRegion(val);
    setFieldValue('state', val);
  };

  const onChangeRadio = (e) => {
    setFieldValue('rented', e.target.value === 'true' ? true : false);
  };

  const handleStateChange = (val) => {
    selectRegion(val);

    if (!isEmpty(values.state)) {
      setFieldValue('city', '');
      setSelectedCity(null);
      setFieldValue('pincode', '');
    }
  };

  const handleCityChange = (selectedOption) => {
    setFieldValue('city', selectedOption?.value || '');
    setSelectedCity(selectedOption);
  };

  // Check if the entered city exists in the list of options.
  const checkCityList = (val) => {
    if (isEmpty(cities)) return false;

    return cities.some((object) => object.value === val);
  };

  // If city is a text-field then append asterisk.
  const handleAsteriskAddition = () => {
    if (isCityTextField && !values.city.endsWith('*')) {
      setFieldValue('city', capitalizeName(getTrimmedValue(values?.city, true)) + `*`);
    }
  };

  return (
    <Modal
      data-testid='address-step-1-form'
      size='lg'
      show={props.show}
      onHide={props.onHide}
      dialogClassName='bgvModalDialog'
      className='bgvModal'
      backdropClassName={'customBGVModalBackdrop'}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div data-testid='header-title' className={styles.step1Title}>{`${props.title}  ${
            props.addressType === 'ANY ADDRESS' ? 'Any' : capitalize(props.addressType)
          } Address`}</div>
          <div className={styles.step1SubTitle}>
            {`Step ${props.currentModalSubStep} of ${props.totalStep}`}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Form
          //   onSubmit={handleSubmit}
          className='add-edit-education-form'
        >
          {props.addressType === 'ANY ADDRESS' ? (
            <Form.Row data-testid='any-dropdown'>
              <Col>
                <Form.Label>
                  Address Type <span style={{ color: 'red' }}>*</span>
                </Form.Label>

                <Select
                  menuPosition={'fixed'}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  options={addressTypeValue}
                  value={addressTypeValue?.filter((a) => a === values?.addressType) || ''}
                  name='addressType'
                  getOptionLabel={(l) => l}
                  getOptionValue={(v) => v}
                  onChange={(value) => {
                    setFieldValue('addressType', value);
                  }}
                />
              </Col>
            </Form.Row>
          ) : null}
          <Form.Row
            style={{
              marginLeft: '1px',
              marginRight: '1px'
            }}
          >
            <Form.Label className={styles.customLabel}>
              Address Line 1<span className={styles.customSpan}>*</span>
            </Form.Label>
            <Field
              data-testid='house-no'
              component={ValidatedFormInputField}
              type='text'
              placeholder='Flat / House No / Floor /Building'
              name='houseNo'
            />
            <Form.Label className={styles.customLabel1}>
              Address Line 2<span className={styles.customSpan}>*</span>
            </Form.Label>
            <Field
              className={styles.customFieldStyle}
              type='text'
              name='colony'
              data-testid='colony'
              placeholder='Colony / Street / Locality'
              component={ValidatedFormInputField}
            />
          </Form.Row>
          <Form.Row
            style={{
              marginTop: '-15px',
              marginBottom: '15px',
              marginLeft: '1px',
              marginRight: '1px'
            }}
          >
            <div>
              <Form.Group className={styles.radioGroup} controlId='rentedOption'>
                {[
                  { label: 'Rented', value: true },
                  { label: 'Own House', value: false }
                ].map((type, index) => {
                  return (
                    <Form.Check
                      type={'radio'}
                      className={styles.radioGroup}
                      key={`rentedOption-${index}`}
                      inline
                    >
                      <Form.Check.Input
                        type={'radio'}
                        name={type.label}
                        id={type.value}
                        onChange={(e) => onChangeRadio(e)}
                        checked={props.values.rented === type.value}
                        value={type.value}
                      />
                      <Form.Check.Label className={styles.radioLabel} htmlFor={type.value}>
                        {type.label}
                      </Form.Check.Label>
                    </Form.Check>
                  );
                })}
              </Form.Group>
            </div>
          </Form.Row>
          <Form.Row
            style={{
              marginLeft: '1px',
              marginRight: '1px'
            }}
          >
            <Form.Label>
              Landmark<span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Field
              component={ValidatedFormInputField}
              type='text'
              placeholder='landmark'
              name='landmark'
            />
          </Form.Row>
          <Form.Row>
            <Col lg={6} md={12} className={styles.mbFor992}>
              <Form.Label>
                Country<span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <div className={styles.dropdown}>
                <CountryDropdown
                  value={country}
                  defaultOptionLabel={
                    props.values.country ? props.values.country : 'Select Country'
                  }
                  onChange={(val) => selectCountry(val)}
                />
              </div>
              {props.errors.country && <div className={styles.error}>Please select country</div>}
            </Col>
            <Col lg={6} md={12}>
              <Form.Label>
                Pincode<span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <Field
                component={ValidatedFormInputField}
                placeholder='Pincode'
                name='pincode'
                noAutoTrimmedRequired
                handleChange={handlePincodeChange}
                onBlur={(e) => {
                  props.handleBlur(e);

                  setFieldValue(
                    'pincode',
                    getTrimmedValue(values?.pincode, values.country !== 'India')
                  );
                }}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg={6} md={12} className={styles.mbFor992}>
              <Form.Label>
                State<span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <div className={styles.dropdown}>
                <RegionDropdown
                  country={props.values.country ? props.values.country : country}
                  defaultOptionLabel={props.values.state ? props.values.state : 'Select State'}
                  value={region}
                  onChange={handleStateChange}
                />
              </div>
              {props.errors.state && <div className={styles.error}>Please select state</div>}
            </Col>
            <Col lg={6} md={12}>
              <Form.Label>
                City<span style={{ color: 'red' }}>*</span>
              </Form.Label>

              {isCityTextField ? (
                <Field
                  type='text'
                  name='city'
                  placeholder='Please type city name'
                  value={getCityValue(values?.city || '')}
                  component={ValidatedFormInputField}
                  onBlur={() => {
                    setFieldValue('city', capitalizeName(getTrimmedValue(values?.city, true)));
                    handleAsteriskAddition();
                  }}
                />
              ) : (
                <Select
                  menuPosition={'fixed'}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  options={cities}
                  value={selectedCity}
                  menuPlacement='top'
                  onChange={handleCityChange}
                  onBlur={(e) => {
                    // Case: Entered city does not exist in the list / case of text-field.
                    if (!isEmpty(e.target.value) && !checkCityList(e.target.value)) {
                      setFieldValue(
                        'city',
                        capitalizeName(getTrimmedValue(e.target.value, true)) + '*'
                      );
                    }
                  }}
                  isClearable
                  noOptionsMessage={() => 'Please type city name'}
                  placeholder={'Select or type city name'}
                  isSearchable
                />
              )}
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          className={'bgvModalSecondaryBtn'}
          onClick={() => {
            props.onHide();
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid='next-btn'
          disabled={!props.values.state || !isEmpty(props.errors) || geoCodeAddressData?.loading}
          className={'bgvModalPrimaryBtn'}
          onClick={props.handleSubmit}
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
