import React from 'react';
import Image from '../../../../core-components/Image';

const UpsellClubBanner = ({ className }) => {
  return (
    <Image
      onClick={() =>
        window.open(
          'https://payment.onepercentclub.io/masterclass/66c746a8cf73016ef6ccc507?source=organic&code=spring99',
          '_blank'
        )
      }
      className={className}
      name={'upsellClubBanner.svg'}
    />
  );
};

export default UpsellClubBanner;
