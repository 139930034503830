// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RfuryAkHoitcTogP_MLg {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 17px;
  flex-wrap: wrap;
}
.RfuryAkHoitcTogP_MLg .nYwbYXURyXgEH_vvDXP4 {
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  background-color: #fff;
  padding: 25px 15px 25px 26px;
  height: 100%;
  width: 187px;
  border-radius: 4px;
  flex-grow: 1;
  cursor: pointer;
}
.RfuryAkHoitcTogP_MLg .nYwbYXURyXgEH_vvDXP4 .mAxqaFyxmIImjnZZP4XQ {
  flex-grow: 1;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
.RfuryAkHoitcTogP_MLg .nYwbYXURyXgEH_vvDXP4 .mAxqaFyxmIImjnZZP4XQ h4 {
  font-size: 24px;
  line-height: 36px;
  color: #646c9a;
  margin: 0;
  margin-bottom: 4px;
}
.RfuryAkHoitcTogP_MLg .nYwbYXURyXgEH_vvDXP4 .mAxqaFyxmIImjnZZP4XQ p {
  font-size: 14px;
  line-height: 21px;
  color: #a7abc3;
  margin: 0;
}

.wZZHGlZpWULU1ddVKD4P {
  display: flex;
  justify-content: space-between;
  margin: 55px 0px 35px 0px;
}
.wZZHGlZpWULU1ddVKD4P .DPaNRB2wYNweXoEOxJNb {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.wZZHGlZpWULU1ddVKD4P button .RKqMIEQqYiO2N7ArincN {
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 21px;
  color: #ffffff;
  width: 164px;
  height: 45px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Dashboard/Dashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AACF;AAAE;EACE,gDAAA;EACA,sBAAA;EACA,4BAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AAEJ;AADI;EACE,YAAA;EACA,gCAAA;EACA,gBAAA;AAGN;AAFM;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;AAIR;AAFM;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AAIR;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,yBAAA;AACF;AAAE;EACE,gCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ;AAAE;EACE,kBAAA;EACA,gCAAA;EACA,gBAAA;EACA,0BAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AAEJ","sourcesContent":[".dashboardCardMain {\n  display: flex;\n  justify-content: space-between;\n  margin: 0 auto 17px;\n  flex-wrap: wrap;\n  .dashboardCardParent {\n    box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);\n    background-color: #fff;\n    padding: 25px 15px 25px 26px;\n    height: 100%;\n    width: 187px;\n    border-radius: 4px;\n    flex-grow: 1;\n    cursor: pointer;\n    .dashboardCard {\n      flex-grow: 1;\n      font-family: Poppins, sans-serif;\n      font-weight: 500;\n      h4 {\n        font-size: 24px;\n        line-height: 36px;\n        color: #646c9a;\n        margin: 0;\n        margin-bottom: 4px;\n      }\n      p {\n        font-size: 14px;\n        line-height: 21px;\n        color: #a7abc3;\n        margin: 0;\n      }\n    }\n  }\n}\n\n.subHeading {\n  display: flex;\n  justify-content: space-between;\n  margin: 55px 0px 35px 0px;\n  .header {\n    font-family: Poppins, sans-serif;\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 36px;\n    color: #ffffff;\n  }\n  button .addCandidateBtn {\n    border-radius: 4px;\n    font-family: Poppins, sans-serif;\n    font-weight: 400;\n    font-size: 1rem !important;\n    line-height: 21px;\n    color: #ffffff;\n    width: 164px;\n    height: 45px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardCardMain": `RfuryAkHoitcTogP_MLg`,
	"dashboardCardParent": `nYwbYXURyXgEH_vvDXP4`,
	"dashboardCard": `mAxqaFyxmIImjnZZP4XQ`,
	"subHeading": `wZZHGlZpWULU1ddVKD4P`,
	"header": `DPaNRB2wYNweXoEOxJNb`,
	"addCandidateBtn": `RKqMIEQqYiO2N7ArincN`
};
export default ___CSS_LOADER_EXPORT___;
