export const generateRazorPayAPIKeySteps = [
  'Log into your RazorpayX Payroll account.',
  'Navigate to the RazorpayX Payroll Integrations tab.',
  'Select the SpringVerify option.',
  'Click on the "Generate Now" button to generate the API key.'
];

export const leverSSOAuthentication = [
  'Click on Connect.',
  'Sign-in to Lever with your user credentials (Lever SuperAdmin Only) and provide the required permissions.'
];
export const leverConfiguration = [
  ' Select the stage from Lever that will push a candidate to SpringVerify for a background check.',
  'Map the relevant tags from Lever to the package variations on SpringVerify.'
];
export const greenHouseAPIAuthentication = [
  'Generate an API key on Greenhouse with the required permissions'
];
export const greenHouseWebHookDetails = [
  'Name: SpringVerify BGV',
  `When: "Candidate has been hired" OR "Offer approved"`
];

export const greytHRAPIKEYGeneration = [
  'Log in to your GreytHR platform.',
  'Go to the Unite by GreytHR page.',
  'Choose the SpringVerify application from the application listing page.',
  'Enable the application to complete the integration.'
];

export const workdayAPIKEYGeneration = [
  'Get your Workday URL',
  'Get your Workday Integration System User name',
  'Get your Workday Integration System User password',
  'Get your Workday Tenant Name',
  'Get your Workday REST Client ID',
  'Get your Workday REST Client Secret',
  'Get your Workday REST Refresh Token'
];
export const zenefitsAPIKEYGeneration = ['Get your Zenefits API Key'];
export const hiBobAPIKEYGeneration = ['Get your HiBob API Key'];

export const darwinboxAPIKEYGeneration = [
  'Get your Darwinbox SubDomain',
  'Get your Darwinbox API Key',
  'Get your Darwinbox DataSet Key',
  'Get your Darwinbox Username',
  'Get your Darwinbox Password'
];

export const oracleCloudHCMAPIKEYGeneration = [
  'Get your Oracle HCM Server Url',
  'Get your Oracle HCM Username',
  'Get your Oracle HCM Password'
];

export const sapSuccessFactorsAPIKEYGeneration = [
  'Get your SuccessFactors API Server Url',
  'Get your SuccessFactors Username',
  'Get your SuccessFactors Company ID',
  'Get your SuccessFactors API Key',
  'Get your SuccessFactors Private Key'
];

export const googleWorkspaceAPIKEYGeneration = [
  'Log in with your Google Workspace account in browser'
];

export const namelyAPIKEYGeneration = ['Get your Namely Personal Access Token'];

export const BambooHRAPIKEYGeneration = ['Get your BambooHR API Key'];

export const workableDetails = ['Get your Workable Access token', 'Get your Workable Subdomain'];
export const humaansDetails = ['Get your Humaans API Key'];
export const hrcloudDetails = [
  'Get your HR Cloud Customer Key',
  'Get your HR Cloud Customer Secret'
];

export const zohoPeopleDetails = ['Login into your Zoho People account'];
export const fountainDetails = ['Get your Fountain API Key', 'Get your Fountain Base URL'];
export const TeamtailorDetails = [
  'Get your Teamtailor API Key',
  'Get your Teamtailor API Base URL'
];
export const JobScoreDetails = ['Get your JobScore API Key'];

export const sprintoDetails = [
  'Login to Sprinto and Go to Settings',
  'Click Integrations',
  'Search SpringVerify in the search box and click connect',
  'Enter API key generated on SpringVerify portal and click connect',
  'User will get the successful message if the API key is valid'
];

export const hroneDetails = [
  'Copy the Generated SpringVerify API Key',
  'Login into HROne',
  'Go to Settings',
  'Click on Integrations',
  'Select Integration Partner as SpringVerify',
  'Paste the copied API Key in the API Key field',
  'Click on Get Packages Button',
  'Select the Package and click on Update to Complete the Integration'
];

export const alexisDetails = ['Get your Alexis Access token', 'Get your Alexis Environment'];
export const breatheDetails = ['Get your breathe API key'];
export const personioDetails = ['Get your Personio Client ID', 'Get your Personio Client Secret'];
export const haileyhrDetails = ['Get your Hailey HR Access Token'];
export const factorialDetails = ['Login to Factorial to auto-connect'];
export const jumpCloudDetails = ['Get your JumpCloud API Key'];
export const heavenHRDetails = ['Get your HeavenHR Client ID', 'Get your HeavenHR Client Secret'];
export const peopleHRDetails = ['Get your PeopleHR API Key'];
export const clayHRDetails = [
  'Get your ClayHRs API Key',
  'Get your ClayHRs User Id',
  'Get your ClayHRs Company'
];
export const gustoDetails = ['Login to Gusto to auto-connect'];
export const jazzHRAPIDetails = [
  `Sign in to your JazzHR account`,
  `Navigate to Settings > Integrations`,
  `Copy your existing API key or click Generate new key`
];

export const kekaDetails = [
  'Sign in to your Keka account',
  `Retrieve your Keka API key and Keka domain from your Keka account`,
  'Click on the Connect button'
];

export const knowledgeBase = {
  Lever:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-lever-with-springverify',
  Greenhouse:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-greenhouse-with-springverify',
  RazorpayX:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-razorpayx-with-springverify',
  Breezy:
    'https://docs.google.com/document/d/1udfhrtkUIxVXaOmb7iYSxD4tYqC8kwVOwISljVjGaCY/edit#heading=h.jemohrddvzbc',
  TalentLyft:
    'https://docs.google.com/document/d/10sS6OYVb1Xu1Xejnr2ookFYbHVxrrAmj-uRdn5Y1b1Y/edit',
  Namely:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-namely',
  'Google Workspace':
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-google-workspace',
  greytHR:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-greythr',
  BambooHR:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-bamboohr',
  Darwinbox:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-darwinbox',
  'Oracle Cloud HCM':
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-oracle-cloud-hcm-oracle-cloud-human-capital-management',
  'SAP SuccessFactors':
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-sap-successfactors',
  Zenefits:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-zenefits',
  Workday:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-workday',
  HiBob:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-hibob',
  Humaans:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-humaans',
  'HR Cloud':
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-hr-cloud',
  Workable:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-workable',
  Fountain:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-fountain-29-1-2024',
  JobScore:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-jobscore',
  Teamtailor:
    'https://support.springworks.in/portal/en/kb/articles/svin-kba-teamtailor-integration',
  'Zoho People':
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-zoho-people',
  Sprinto:
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-sprinto',
  AlexisHR: '',
  'Breathe HR': '',
  Personio: '',
  'Hailey HR': '',
  Factorial: '',
  JumpCloud: '',
  Gusto: '',
  PeopleHR: '',
  ClayHR: '',
  HeavenHR: '',
  JazzHR: `https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-jazzhr`,
  Keka: 'https://support.springworks.in/portal/en/kb/articles/integrating-springverify-with-keka-17-7-2024-1',
  'BambooHR (ATS)':
    'https://support.springworks.in/portal/en/kb/articles/integration-of-springverify-with-bamboohr',
  default:
    'https://support.springworks.in/portal/en/kb/springverify-india/client-knowledge-base/integrations'
};
export const integrationUrl = {
  Lever: 'https://www.lever.co/',
  Greenhouse: 'https://www.greenhouse.com/',
  Recruitee: 'https://recruitee.com/',
  'Zoho Recruit': 'https://www.zoho.com/recruit',
  BambooHR: 'https://www.bamboohr.com/',
  Darwinbox: 'https://darwinbox.com/',
  greytHR: 'https://www.greythr.com/',
  'Google Workspace': 'https://workspace.google.com/',
  Namely: 'https://www.namely.com/',
  'Oracle Cloud HCM': 'https://www.oracle.com/in/human-capital-management/',
  'SAP SuccessFactors': 'https://www.sap.com/',
  Zenefits: 'https://www.zenefits.com/',
  Workday: 'https://www.workday.com/',
  HiBob: 'https://www.hibob.com/',
  Humaans: 'https://humaans.io/',
  'HR Cloud': 'https://www.hrcloud.com/',
  Workable: 'https://www.workable.com/',
  Repute: 'https://repute.net/',
  Fountain: 'https://www.fountain.com/',
  JobScore: 'https://www.jobscore.com/',
  Teamtailor: 'https://www.teamtailor.com/en/',
  'Zoho People': 'https://www.zoho.com/people/',
  Sprinto: 'https://www.sprinto.com/',
  AlexisHR: 'https://www.alexishr.com/',
  'Breathe HR': 'https://www.breathehr.com/',
  Personio: 'https://www.personio.com/',
  'Hailey HR': 'https://www.haileyhr.com/',
  Factorial: 'https://factorialhr.com/',
  JumpCloud: 'https://jumpcloud.com/',
  HeavenHR: 'https://www.heavenhr.com/',
  PeopleHR: 'https://www.peoplehr.com/',
  ClayHR: 'https://www.clayhr.com/',
  Gusto: 'https://gusto.com/',
  'BambooHR (ATS)': 'https://www.bamboohr.com/',
  JazzHR: 'https://www.jazzhr.com/',
  Keka: 'https://www.keka.com/',
  HROne: 'https://hrone.cloud/'
};
export const integrationText = {
  Lever: 'www.lever.co',
  Greenhouse: 'www.greenhouse.com',
  Recruitee: 'www.recruitee.com',
  'Zoho Recruit': 'www.zoho.com/recruit',
  BambooHR: 'www.bamboohr.com',
  Darwinbox: 'darwinbox.com',
  greytHR: 'www.greythr.com',
  'Google Workspace': 'workspace.google.com',
  Namely: 'www.namely.com',
  'Oracle Cloud HCM': 'www.oracle.com/in/human-capital-management',
  'SAP SuccessFactors': 'www.sap.com',
  Zenefits: 'www.zenefits.com',
  Workday: 'www.workday.com',
  HiBob: 'www.hibob.com',
  Humaans: 'www.humaans.io',
  'HR Cloud': 'www.hrcloud.com',
  Workable: 'www.workable.com',
  Repute: 'repute.net',
  Fountain: 'fountain.com',
  JobScore: 'jobscore.com',
  Teamtailor: 'teamtailor.com',
  'Zoho People': 'zoho.com',
  Sprinto: 'sprinto.com',
  AlexisHR: 'alexishr.com',
  'Breathe HR': 'breathehr.com',
  Personio: 'personio.com',
  'Hailey HR': 'haileyhr.com',
  Factorial: 'factorialhr.com',
  JumpCloud: 'jumpcloud.com',
  HeavenHR: 'heavenhr.com',
  PeopleHR: 'peoplehr.com',
  ClayHR: 'clayhr.com',
  Gusto: 'gusto.com',
  'BambooHR (ATS)': 'www.bamboohr.com',
  JazzHR: 'jazzhr.com',
  Keka: 'keka.com',
  HROne: 'hrone.cloud'
};
export const generateRecruiteeAPIKeySteps = [
  'Log into your Recruitee account',
  'Navigate to the Recruitee integrations tab',
  'Select SpringVerify',
  'Click on "Generate Now" button to generate the APi key'
];

export const recruiteeSourceName = ['RECRUITEE'];
