import { isEmpty, has } from 'lodash';
import { getTotalAnyCount } from '../../utils/utilities';

const getIsCheckValid = (check, isSkipped, config) => {
  let isValid = false;
  if ((check && check.length >= config) || isSkipped) {
    isValid = true;
  }
  return isValid;
};
export default (formValues, sidepanelSeqId, config, cacheData, companyConfig) => {
  let finalReturn = { shouldSubmit: false, errorParams: {} };

  const creditConfigCount = () => {
    if (getTotalAnyCount(config.identity) === formValues['id_check'].length) {
      return getTotalAnyCount(config.identity);
    } else {
      return getTotalAnyCount(config.identity) + 1;
    }
  };

  const identityConfigCount = config.identity
    ? config.identity.includes('ALL')
      ? 5
      : config?.credit
      ? creditConfigCount()
      : getTotalAnyCount(config.identity)
    : 0;

  const getRefEmpCount = () => {
    let totalRefEmpCount = 0;
    let refEmpArray = [];
    let actualRefCount = 0;
    let empToCollect = 0;
    if (config.reference.length && config.reference.length > config.employment) {
      // refEmpArray = [1, 0];
      refEmpArray = config.reference;

      // && parseInt(refEmpArray[refEmpArray.length - 1]) > 0
      actualRefCount = refEmpArray.reduce((a, b) => Number(a) + Number(b));
      if (actualRefCount > 0) {
        refEmpArray.forEach((ref, index) => {
          if (
            parseInt(ref) > 0 ||
            (totalRefEmpCount < actualRefCount && index < refEmpArray.length - 1)
          ) {
            totalRefEmpCount += parseInt(ref);
            empToCollect++;
          }
        });
      }
      return empToCollect;
    } else {
      return config.employment;
    }
  };
  const employmentConfigCount = getRefEmpCount() || 0;
  const educationConfigCount = config.education
    ? Array.isArray(config.education)
      ? getTotalAnyCount(config.education)
      : config.education
    : 0;
  let addressConfigCount = 0;

  if (cacheData.cache_data.address_check) {
    cacheData.cache_data.address_check.forEach((element) => {
      if (has(element, 'step_1') && has(element, 'step_2') && has(element, 'step_3')) {
        ++addressConfigCount;
      }
    });
  }
  const getSkippedChecks = () => {
    let skippedChecks = {
      id_check: 0,
      employment_check: 0,
      education_check: 0,
      address_check: 0
    };
    if (identityConfigCount) {
      skippedChecks.id_check = identityConfigCount - formValues['id_check'].length;
    }
    if (employmentConfigCount) {
      let employmentLength = formValues['employment_check']
        ? formValues['employment_check'].length
        : 0;
      skippedChecks.employment_check = employmentConfigCount - employmentLength;
    }
    if (educationConfigCount) {
      skippedChecks.education_check = educationConfigCount - formValues['education_check'].length;
    }
    if (addressConfigCount) {
      skippedChecks.address_check = addressConfigCount - formValues['address_check'].length;
    }
    return {
      ...skippedChecks
    };
  };

  if (formValues && !isEmpty(formValues)) {
    if (
      sidepanelSeqId.includes(-1) &&
      (!formValues['basic_details'] ||
        (formValues['basic_details'] && !formValues['basic_details']?.full_name) ||
        (!companyConfig?.is_gender_optional && formValues['basic_details']?.gender === ''))
    ) {
      finalReturn.errorParams = { state: -1, msg: 'Please fill Basic Detail Section' };
      return finalReturn;
    } else if (
      sidepanelSeqId.includes(1) &&
      !getIsCheckValid(
        formValues['id_check'] || null,
        cacheData['identity_skip_enable'] || false,
        identityConfigCount
      )
    ) {
      finalReturn.errorParams = { state: 1, msg: 'Please fill Identity Detail Section' };
      return finalReturn;
    } else if (
      sidepanelSeqId.includes(4) &&
      !getIsCheckValid(
        formValues['employment_check'] || null,
        cacheData['skipped_employment'] || false,
        employmentConfigCount
      )
    ) {
      finalReturn.errorParams = { state: 4, msg: 'Please fill Employment Detail Section' };
      return finalReturn;
    } else if (
      sidepanelSeqId.includes(5) &&
      !getIsCheckValid(
        formValues['education_check'] || null,
        cacheData['is_skip_education'] || false,
        educationConfigCount
      )
    ) {
      finalReturn.errorParams = { state: 5, msg: 'Please fill Education Detail Section' };
      return finalReturn;
    } else if (
      sidepanelSeqId.includes(3) &&
      !getIsCheckValid(
        formValues['address_check'] || null,
        cacheData['address_skip_enable'] || false,
        addressConfigCount
      )
    ) {
      finalReturn.errorParams = { state: 3, msg: 'Please fill Address Detail Section' };
      return finalReturn;
    } else {
      finalReturn.shouldSubmit = true;
      finalReturn.skipped_checks = getSkippedChecks();
      return finalReturn;
    }
  }
};
