/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { has, toLower } from 'lodash';
import { Table, Card, Tooltip, Form, Col, OverlayTrigger } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import CategoryModals from '../CategoryModals';
import AdminCard from './AdminCard';
import Button from '../../../core-components/Button';
import Icon from '../../../core-components/Icon';
import Image from '../../../core-components/Image';
import Pagination from '../../../core-components/Pagination';
import styles from './Administrators.module.scss';
import { convertToIndianNumeration } from '../../../utils/utilities';
import { capitalizeName } from '../frontend-common/utils/Utilities';
import AppHelper from '../../../core-components/AppHelper';

export default class extends React.Component {
  pageSizes = [10, 25, 50, 100];
  defaultPageSize = this.pageSizes[0];
  maxPageSize = this.pageSizes[this.pageSizes.length - 1];

  constructor(props) {
    super(props);
    this.state = {
      pageSize: this.pageSizes[0],
      pageNumber: 0,
      query: '',
      count: 0,
      data: [],
      isData: true,
      loading: true,
      modalShow: false,
      modalShowStatus: false,
      modalTitle: null,
      modalSubTitle: null,
      modalButton: null,
      id: null,
      selectedRow: null,
      pageReload: false,
      showAdminCard: false,
      isEditAdmin: false,
      editAdminData: null
    };
  }

  modalToggle = (modalTitle, modalSubTitle, modalButton, adminId) => {
    this.setState({
      modalShow: true,
      modalTitle: modalTitle,
      modalSubTitle: modalSubTitle,
      modalButton: modalButton,
      id: adminId
    });
  };

  modalHide = (value) => {
    if (value === 'reload') {
      this.setState({
        pageReload: true,
        modalShow: false
      });
      this.fetchAdministrators(this.state.pageSize, this.state.pageNumber);
    } else {
      this.setState({
        modalShow: false
      });
    }
  };

  modalHideStatus = () => {
    this.setState({
      modalShowStatus: false
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props?.invalidateAdministrators();
    let limit = this.state.pageSize;
    let offset = this.state.pageNumber;
    this.fetchAdministrators(limit, offset);
  }

  fetchAdministrators(limit, offset) {
    this.setState({
      loading: true
    });
    this.props?.getAdministrators(limit.toString(), offset.toString())?.then(() => {
      this.setState({
        data: this.props?.administrators?.admins,
        count: this.props?.administrators?.count,
        loading: this.props?.administrators?.loading,
        isData: this.props?.administrators?.count === 0 ? false : true
      });
      this.props?.getPaymentDetails();
    });
  }

  paginate(limit, offset) {
    this.fetchAdministrators(limit, offset);
  }

  getCategoryString = (userCategories) => {
    let catString = '';
    if (userCategories && userCategories.length > 0) {
      for (let value of userCategories) {
        if (has(value, 'userCategory.categoryName')) {
          catString = catString + value['userCategory']['categoryName'] + ', ';
        }
      }
      catString = catString.substring(0, catString.length - 2);
    }
    return catString ? catString : '-';
  };

  componentDidUpdate() {
    if (this.props?.location?.search && !this.state.showAdminCard) {
      const query = new URLSearchParams(this?.props?.location?.search);
      const isAddAdminModalOpen = query.get('_openAddUserModal');

      if (isAddAdminModalOpen) {
        this.showAdminCardHandler();
      }
    }
  }

  showAdminCardHandler = (callAPI = false) => {
    this.setState({
      ...this.state,
      showAdminCard: !this.state.showAdminCard,
      isEditAdmin: false,
      editAdminData: null
    });
    if (callAPI) {
      //this function call from submithandler from AdninCard.jsx i.e afetr adding/editing user call api to get latest data
      this.fetchAdministrators(this.state.pageSize, this.state.pageNumber);
    }
  };

  showAdminCardHandlerWrapper = (callAPI = false) => {
    this.props.history.push({ pathname: '/myTeam' });
    this.showAdminCardHandler(callAPI);
  };

  editAdminHandler = (rowData) => {
    this.setState({
      ...this.state,
      showAdminCard: !this.state.showAdminCard,
      isEditAdmin: !this.state.isEditAdmin,
      editAdminData: rowData
    });
  };

  render() {
    const {
      pageNumber,
      pageSize,
      count,
      data,
      loading,
      showAdminCard,
      isEditAdmin,
      editAdminData
    } = this.state;

    const pageSizeOptions = [];
    let index = 0;

    do {
      const value = this.pageSizes[index] > count ? count : this.pageSizes[index];

      if (!value) break;

      pageSizeOptions.push(
        <option key={index} value={value}>
          {value}
        </option>
      );
    } while (this.pageSizes[index++] < count);

    const getEmail = (value) => {
      return (
        <>
          {toLower(value?.email)}{' '}
          {value?.userEmailRejectType !== 'NA' && value?.userEmailRejectType !== '' ? (
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id={'EmailBounced'}>
                  <p>{'Email has bounced'}</p>
                </Tooltip>
              }
            >
              <span className={styles.mailIcon}>
                <Image name='emailBouncedIcon.svg' />
              </span>
            </OverlayTrigger>
          ) : (
            ''
          )}
        </>
      );
    };

    return (
      <div className={styles.adminWrapper}>
        {!showAdminCard ? (
          <>
            <div className={styles.adminHeader}>
              <div className={styles.adminHeading}>{'My Team'}</div>
              <Link to={{ pathname: '/myTeam', search: '?_openAddUserModal=true' }}>
                <Button className={styles.addAdminButton} data-testid='modalHideStatusButton'>
                  {'+ Add User'}
                </Button>
              </Link>
            </div>
            {this.state.modalShow && (
              <CategoryModals
                show={this.state.modalShow}
                onHide={this.modalHide}
                title={this.state.modalTitle}
                subTitle={this.state.modalSubTitle}
                button={this.state.modalButton}
                adminId={this.state.id}
              />
            )}
            <Card className={styles.adminCard}>
              <div className={styles.adminCardHeading}>
                {!loading && `${convertToIndianNumeration(count)} Members`}
              </div>
              <Card className={styles.tableCard}>
                <Table className={styles.administratorsTable}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th className={styles.alignRight}>Candidates Added</th>
                      <th>Categories</th>
                      <th>Access</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {this.state.isData ? (
                    <tbody>
                      {loading
                        ? Array.apply(null, { length: pageSize })
                            .map(Number.call, Number)
                            .map((_loadData, i) => {
                              return (
                                <tr key={i}>
                                  <td className={styles.mainField}>
                                    <ContentLoader height={20} width={100}>
                                      <rect x='0' y='0' width='100' height='14' />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader height={20} width={200}>
                                      <rect x='0' y='0' width='200' height='14' />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader height={20} width={100}>
                                      <rect x='0' y='0' width='100' height='14' />
                                    </ContentLoader>
                                  </td>
                                  <td className={styles.alignRight}>
                                    <ContentLoader height={20} width={50}>
                                      <rect x='0' y='0' width='50' height='14' />
                                    </ContentLoader>
                                  </td>
                                  <td className={styles.alignRight}>
                                    <ContentLoader height={20} width={50}>
                                      <rect x='0' y='0' width='50' height='14' />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader height={20} width={50}>
                                      <rect x='0' y='0' width='50' height='14' />
                                    </ContentLoader>
                                  </td>
                                  <td className={styles.alignRight}>
                                    <ContentLoader height={20} width={80}>
                                      <rect x='0' y='0' width='80' height='14' />
                                    </ContentLoader>
                                  </td>
                                </tr>
                              );
                            })
                        : data &&
                          data.map((rowData, i) => {
                            return (
                              <tr key={i}>
                                <td className={styles.mainField}>
                                  {rowData?.id === this.props?.profile?.id
                                    ? capitalizeName(rowData?.name) + ' (You)'
                                    : capitalizeName(rowData?.name)}
                                </td>
                                <td>{getEmail(rowData)}</td>
                                <td>{rowData?.mobile}</td>
                                <td className={styles.alignRight}>
                                  {rowData?.candidatesAdded > 0
                                    ? convertToIndianNumeration(rowData?.candidatesAdded)
                                    : '-'}
                                </td>
                                <td>{this.getCategoryString(rowData?.userCategoryMappings)}</td>
                                <td>
                                  {rowData?.accessLevel &&
                                    (rowData.accessLevel === 5
                                      ? 'Admin'
                                      : rowData.accessLevel === 10
                                      ? 'HR'
                                      : '-')}
                                </td>
                                <td>
                                  <div className={styles.icons}>
                                    <OverlayTrigger
                                      placement='bottom'
                                      overlay={
                                        <Tooltip id={'EditUser'}>
                                          <p>{'Edit'}</p>
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className={styles.icon}
                                        onClick={() => this.editAdminHandler(rowData)}
                                      >
                                        <Icon
                                          icon={'EditLarge'}
                                          color={'#333333'}
                                          width={22}
                                          height={22}
                                        />
                                      </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement='bottom'
                                      overlay={
                                        <Tooltip id={'emailPreferences'}>
                                          <p>{'Email preferences'}</p>
                                        </Tooltip>
                                      }
                                    >
                                      <Link
                                        onClick={() => {
                                          const env = AppHelper.getEnv();
                                          const { companyUserMapping = {} } = rowData;
                                          const { userId = '', companyId = '' } =
                                            companyUserMapping;
                                          const params = `?id=${userId}&companyId=${companyId}`;
                                          const url =
                                            env === 'local'
                                              ? `http://localhost:3003/v1/ca/email-preferences${params}`
                                              : `/v1/email-preferences${params}`;
                                          window.location.href = url;
                                        }}
                                      >
                                        <div className={styles.icon}>
                                          <Icon
                                            icon={'EmailPreferencesLarge'}
                                            color={'#333333'}
                                            width={22}
                                            height={22}
                                          />
                                        </div>
                                      </Link>
                                    </OverlayTrigger>
                                    {rowData?.email === this.props?.profile?.email ? (
                                      <div className={styles.space}></div>
                                    ) : (
                                      <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                          <Tooltip id={'DeleteUser'}>
                                            {rowData?.isAdminDeletionAllowed
                                              ? 'Delete'
                                              : 'User deletion is not permitted because important notifications are enabled for this user.'}
                                          </Tooltip>
                                        }
                                      >
                                        <button
                                          disabled={!rowData?.isAdminDeletionAllowed}
                                          className={styles.icon}
                                          onClick={() => {
                                            this.modalToggle(
                                              'Delete Admin?',
                                              'Are you sure you want to delete this administrator?',
                                              'Delete',
                                              rowData?.companyUserMapping?.userId
                                            );
                                          }}
                                        >
                                          <Icon
                                            icon={'DeleteLarge'}
                                            color={'#333333'}
                                            width={22}
                                            height={22}
                                          />
                                        </button>
                                      </OverlayTrigger>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={7}>
                          <div className={styles.noDataWrapper}>
                            <Image name={'NoData.svg'} />
                            <div className={styles.noDataContent}>{'No administrators found'}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
                {this.state.isData ? (
                  <Form.Row className={styles.paginationWrapper}>
                    <Col className={styles.showPagination}>
                      Show
                      <select
                        className={styles.selectPagination}
                        value={pageSize}
                        onChange={(event) => {
                          this.setState(
                            {
                              pageNumber: 0,
                              pageSize: parseInt(event.target.value)
                            },
                            this.paginate(event.target.value, 0)
                          );
                        }}
                      >
                        {pageSizeOptions}
                      </select>
                      items of {count}
                    </Col>
                    <Col>
                      <Pagination
                        forcePage={pageNumber}
                        onPageChange={({ selected }) =>
                          this.setState({ pageNumber: selected }, this.paginate(pageSize, selected))
                        }
                        pageCount={count / pageSize}
                      />
                    </Col>
                    <Col></Col>
                  </Form.Row>
                ) : (
                  <div></div>
                )}
              </Card>
            </Card>
          </>
        ) : (
          <AdminCard
            showAdminCard={showAdminCard}
            title={isEditAdmin ? 'Edit User' : 'Add New User'}
            showAdminCardHandler={this.showAdminCardHandlerWrapper}
            editAdminData={editAdminData}
            isEditAdmin={isEditAdmin}
          />
        )}
      </div>
    );
  }
}
