// Import required libraries and packages
import React, { useEffect, useState, createContext } from 'react';
import { Card } from 'react-bootstrap';
import _, { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

// Utils
import {
  errToastMessage,
  toCamelCase,
  validateCategoriesAndTags,
  isCustomisable
} from '../../../utils/utilities';
import {
  integrationServicesMapping,
  METADATA_TYPES
} from '../frontend-common/utils/CommonConstants';
import { integrationsCardData } from '../frontend-common/Integrations/integrationItems';

// Import core components
import Loader from '../../../core-components/Loader';
import PaymentResults from '../../../core-components/PaymentResults';

// Import APIs required
import {
  submitAddCandidateConfig,
  candidatesBulkUpload,
  getAddCandidateConfig as getAddCandidateConfigAPI,
  getIntegrations,
  getBrandSettings
} from '../../../api/company';
import { UPLOAD_STATE } from '../../../utils/commonConstant';

import BasicDetails from './BasicDetails';
import PackageListing from './PackageListing';
import SelectedPackage from './SelectedPackage';
import Step3Values from './Step3IntialJson';
import formatter from './CreateCandSubmit.formatter';

import styles from './CreateCandHOC.module.scss';
import AccessRestricted from './AccessRestricted/AccessRestricted';
import ConnectModal from './BasicDetails/Components/AtsHrmsConnectModal';
export const getCandidateConfigContext = createContext();

export default (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryTypeId, setCategoryTypeId] = useState(null);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [categoriesOrTagsDeleted, setCategoriesOrTagsDeleted] = useState({});
  const [isGoingBackToStep1, setIsGoingBackToStep1] = useState(false);
  const [isGoingBackToStep2, setIsGoingBackToStep2] = useState(false);
  const [brandSettingsData, setBrandSettingsData] = useState(null);
  const [integrationsData, setIntegrationsData] = useState(null);
  const [combinedHrmsAtsList, setCombinedHrmsAtsList] = useState([]);
  const [optionsForConnection, setOptionsForConnection] = useState([]);
  const [isAtsHrmsPopUpShownThisWeek, setIsAtsHrmsPopUpShownThisWeek] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [uploadState, setUploadState] = useState(UPLOAD_STATE.NOT_UPLOADED);
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Setting step3 initial values
    setValues({ step3: JSON.parse(JSON.stringify(Step3Values)) });

    // if navigated to this page from add bulk candidate flow then render step = 2, directly
    if (props && props?.finalData?.isBulkUpload && props?.finalData?.step === 2) {
      const currentStepFromBulkUpload = props && props?.finalData?.step;
      const categoryTypeId = props && props?.finalData?.categoryTypeId;
      const tableData = props && props?.finalData?.tableData;
      const companyId = props && props?.finalData?.companyId;
      const fileId = props?.fileId || null;
      currentStepFromBulkUpload ? setCurrentStep(currentStepFromBulkUpload) : setCurrentStep(1);
      setCategoryTypeId(categoryTypeId);
      setIsBulkUpload(true);
      setTableData(tableData);
      setCompanyId(companyId);
      setFileId(fileId);
    }

    const lastShownTimestamp = localStorage.getItem('last_integration_pop_up_shown');

    if (props?.profile?.access_level === 5) {
      if (lastShownTimestamp) {
        const now = new Date();
        const lastShownDate = new Date(parseInt(lastShownTimestamp, 10));
        const oneWeek = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds

        // Check if the difference between now and the last shown time is less than a week
        if (now - lastShownDate < oneWeek) {
          setIsAtsHrmsPopUpShownThisWeek(true);
        } else {
          // If more than a week has passed, show the banner again and update local storage
          setIsAtsHrmsPopUpShownThisWeek(false);
          showBannerAndUpdateTimestamp();
        }
      } else {
        // If no timestamp is found, it means the banner hasn't been shown before
        showBannerAndUpdateTimestamp();
      }
    }

    async function fetchIntegrations() {
      try {
        const res = await getIntegrations();
        const integrations = res?.data?.data?.integrations || null;
        if (integrations) {
          const integrationNames = Object.keys(integrations).map(
            (key) => integrationServicesMapping[key]
          );
          const filteredIntegrationsData = integrationNames?.filter(
            (integration) => integration !== undefined
          );
          setIntegrationsData(filteredIntegrationsData);
        }
      } catch (error) {
        toast.error('Failed to fetch integrations:', error);
      }
    }

    async function fetchBrandSettings() {
      try {
        const res = await getBrandSettings();
        setBrandSettingsData(res?.data?.data);
      } catch (error) {
        toast.error('Failed to fetch brand settings:', error);
      }
    }

    fetchIntegrations();
    fetchBrandSettings();
  }, []);

  useEffect(() => {
    const filteredData = getAtsHrmsCombinedList(brandSettingsData);
    setCombinedHrmsAtsList(filteredData);
  }, [brandSettingsData, integrationsData]);

  useEffect(() => {
    /***
     * Build a list of integrations that are available for connection.
     *
     * How do we determine if an integration is available for connection?
     * * 1. Based on the company's metadata, we get a list of ATS and HRMS integrations combined.
     * * 2. If some integration name is present in the /integrations API response but not in the metadata, then it is not connected.
     * * 3. Now we have filtered such ats and hrms which are currently not connected.
     * * 4. If the integrations is available in the integrationsCardData and comingSoon flag is false then it is available for connection.
     * * 5. Now we only choose those integrations which are present in both the lists i.e notConnectedIntegrations and integrationsWhichAreCurrentlyListedInSv
     */
    if (combinedHrmsAtsList?.length) {
      // If the  ats or hrms is not present in the integrationsData but available in the combinedHrmsAtsList then it is not connected
      const notConnectedIntegrations = integrationsData?.length
        ? combinedHrmsAtsList.filter((option) => {
            return !integrationsData.includes(option);
          })
        : combinedHrmsAtsList;

      const integrationsWhichAreCurrentlyListedInSv = [];

      // Filter out the available integrations from the integrationsCardData i.e comingSoon = false
      Object.values(integrationsCardData)?.map((data) => {
        if (!data?.comingSoon) integrationsWhichAreCurrentlyListedInSv.push(data?.title);
      });

      // If the notConnectedIntegrations is available in the integrationsCardData then it is available for connection
      const notConnectedButAvailableForConnectionIntegration = notConnectedIntegrations.filter(
        (option) => {
          return integrationsWhichAreCurrentlyListedInSv.includes(option);
        }
      );

      setOptionsForConnection([...notConnectedButAvailableForConnectionIntegration]);
    }
  }, [combinedHrmsAtsList, integrationsData]);

  useEffect(() => {
    setValues({ ...values, step3: JSON.parse(JSON.stringify(Step3Values)) });
  }, [selectedPackageId]);

  useEffect(() => {
    if (isBulkUpload) {
      if (isSubmitting) {
        props?.setMsg({
          load: true,
          msg: 'Adding Candidates...'
        });
      } else {
        props?.setMsg({
          load: false,
          msg: ''
        });
      }
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (isEmpty(categoriesOrTagsDeleted)) {
      setLoading(true);
      props
        .getAddCandidateConfig()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          errToastMessage(error);
          setLoading(false);
        });
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (currentStep === 2) {
      setLoading(true);
      props
        .getAddCandidateConfig()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          errToastMessage(error);
          setLoading(false);
        });
    }
  }, [currentStep]);

  useEffect(() => {
    if (paymentStatus == 'failed') setCurrentStep(4);
  }, [paymentStatus]);

  useEffect(() => {
    if (props.createCandConfig && !isEmpty(props.createCandConfig)) {
      if (
        props.createCandConfig.is_adding_candidate_allowed !== undefined &&
        props.createCandConfig.is_adding_candidate_allowed === false
      )
        setCurrentStep(5);
    }
  }, [props?.createCandConfig]);

  useEffect(() => {
    if (!isAtsHrmsPopUpShownThisWeek && props?.profile?.access_level === 5) {
      setShowConnectModal(true);
    } else {
      setShowConnectModal(false);
    }
  }, [isAtsHrmsPopUpShownThisWeek]);

  /**
   * Retrieves the combined list of ATS and HRMS integration options from the provided brand settings `data.
   *
   * @param {Object} brandSettingsData - The brand settings data containing company metadata.
   * @returns {Array} - The combined list of ATS and HRMS integration options.
   */
  const getAtsHrmsCombinedList = (brandSettingsData) => {
    if (brandSettingsData) {
      const metaData = brandSettingsData?.company_metadata;

      if (!metaData || !metaData?.length) return [];

      const integrationOptions = metaData
        .filter((data) => data?.type === METADATA_TYPES.ATS || data?.type === METADATA_TYPES.HRMS)
        .reduce((options, data) => {
          return data.value?.length ? [...options, ...data.value] : options;
        }, [])
        .map((option) => option.trim());

      return integrationOptions || [];
    }
  };

  /**
   * Shows the banner to the user and updates the timestamp in local storage.
   */
  const showBannerAndUpdateTimestamp = () => {
    // Logic to show the banner

    // Update the timestamp in local storage
    const now = new Date();
    localStorage.setItem('last_integration_pop_up_shown', now.getTime().toString());
  };

  const handleSelectedSubtypeId = (subtypeId) => {
    setSelectedPackageId(subtypeId);
    setSelectedPackage(
      props.createCandConfig.packages.filter((pckg) => pckg.subtypeId === subtypeId)[0]
    );
  };
  //Here : A STEP SUBMIT
  const handleStepSubmit = (type, formValues, nextStep = null) => {
    let currentValues = { ...values };

    /**
     if condition is added to handle TDS value change and save it in state so that 
     saved value can be used to select the tds radio option when uses goes forward and backward in flow i.e to maintain the state
     *  */
    if (typeof formValues === 'object' && formValues.hasOwnProperty('tdsValue')) {
      currentValues[type] = { ...currentValues[type], tdsValue: formValues?.tdsValue };
    } else {
      for (let index = 0; index < type.length; index++) {
        currentValues[type[index]] = formValues[index];
      }
    }

    setValues(currentValues);

    // Updating address verification types if going back to step 2
    if (isGoingBackToStep2 && type.includes('step2')) {
      const { address: addressConfig } = formValues[0]?.customise || {};
      const { address } = selectedPackage.config || {};

      address.forEach((addr, index) => {
        const baseConfig = addressConfig.baseConfig[index];
        if (baseConfig && baseConfig.verificationType !== addr.verificationType) {
          setValues((prevValues) => {
            const updatedBaseConfig = prevValues.step2.customise.address.baseConfig.map(
              (config, i) =>
                i === index ? { ...config, verificationType: addr.verificationType } : config
            );

            return {
              ...prevValues,
              step2: {
                ...prevValues.step2,
                customise: {
                  ...prevValues.step2.customise,
                  address: {
                    ...prevValues.step2.customise.address,
                    baseConfig: updatedBaseConfig
                  }
                }
              }
            };
          });
        }
      });
    }

    if (nextStep && nextStep !== 'submit' && currentStep !== nextStep) {
      handleStepChange(nextStep);
    } else if (nextStep === 'submit') {
      startSubmit();
    }
  };

  const handleStepChange = (step, isNotCustom = false) => {
    if (step === 3 && values.step2?.customise && isNotCustom) {
      setValues({ ...values, step2: null });
    }

    setCurrentStep(step);

    if (step === 2 && currentStep === 3) {
      setIsGoingBackToStep2(true);
    } else {
      setIsGoingBackToStep2(false);
    }

    // this is to cehck if we going back to basic detials page or not
    if (step === 1) {
      setIsGoingBackToStep1(true);
    } else {
      setIsGoingBackToStep1(false);
    }
  };

  const getDaysDiffForReferralBanner = (storedMonth) => {
    const currentMonth = new Date()?.getMonth()?.toString();

    return storedMonth !== currentMonth ? true : false;
  };

  //Here : Final SUBMIT
  const startSubmit = () => {
    if (props.createCandConfig && !isEmpty(props.createCandConfig)) {
      setIsSubmitting(true);
      const { addons } = props.createCandConfig;
      const payload = formatter(
        values,
        selectedPackage,
        addons,
        props.createCandConfig.includeAadhaarInIdCheck === 1 ? true : false
      );
      delete payload.candidate['resumeFile'];
      delete payload.candidate['consentFile'];
      if (payload?.candidate?.altPhone) {
        payload.candidate['phone_numbers'] = [];
        payload.candidate['phone_numbers'].push({
          belongs_to: 'CANDIDATE',
          data: payload?.candidate?.altPhone
        });
      }
      if (isBulkUpload) {
        payload['candidates'] = tableData;
        payload['category_id'] = categoryTypeId;
        payload['invite'] = true;
        payload['company_id'] = companyId;
        if (fileId) {
          payload['file_id'] = fileId;
        }
        delete payload['candidate'];

        candidatesBulkUpload(payload)
          .then((resp) => {
            toast.success('Candidates are created successfully');
            props.history.replace(location.pathname, null);
            // Month is stored in LS starting from the index 0
            const referModalLastShownMonth = localStorage.getItem('referModalLastShownMonth');
            const currentMonth = new Date()?.getMonth();
            if (
              !referModalLastShownMonth ||
              getDaysDiffForReferralBanner(referModalLastShownMonth)
            ) {
              localStorage.setItem('referModalLastShownMonth', currentMonth);
              props.history.push({
                pathname: '/candidates',
                search: `?_showReferModal=true`
              });
            } else {
              props.history.push({
                pathname: '/candidates'
              });
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            errToastMessage(error);
          });
      } else {
        checkCategoriesAndTagsAreValid(payload);
      }
    }
  };

  const checkCategoriesAndTagsAreValid = async (payload) => {
    const values = payload?.candidate;
    const selectedCategoryId = values?.category_id;
    const selectedTags = values?.tags;

    if (!selectedCategoryId && (!selectedTags || selectedTags.length === 0)) {
      submitIndividualCandidate(payload);
      setIsSubmitting(true);
      return;
    }

    try {
      const response = await getAddCandidateConfigAPI(companyId);
      const config = response?.data || {};
      const categoriesList = config?.categories;
      const tags = config?.tags;
      const res = validateCategoriesAndTags(values, categoriesList, tags);
      if (isEmpty(res)) {
        submitIndividualCandidate(payload);
        setIsSubmitting(true);
        return;
      }
      setCategoriesOrTagsDeleted(res);
      handleStepChange(1);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.error('handleContinue Error: ', err);
    }
  };

  const submitIndividualCandidate = (payload) => {
    submitAddCandidateConfig(payload)
      .then((resp) => {
        setIsSubmitting(false);
        let candidateId = toCamelCase(resp.data.data).candidate.id;
        props.history.push({
          pathname: '/viewCandidate',
          search: `?candidateId=${candidateId}`,
          state: values?.step1?.invite === true ? 'invite' : 'candidateAdded'
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
        errToastMessage(err);
      });
  };

  const getFormBasedOnStep = (step) => {
    switch (step) {
      case 1:
        return (
          <BasicDetails
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            uploadState={uploadState}
            setUploadState={setUploadState}
            file={file}
            setFile={setFile}
            ctError={categoriesOrTagsDeleted}
            setCtError={setCategoriesOrTagsDeleted}
            handleStepChange={(step) => handleStepChange(step)}
            savedValues={values && values.step1 ? values.step1 : null}
            isGoingBackToStep1={isGoingBackToStep1}
          />
        );
      case 2:
        return (
          <PackageListing
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            handleStepChange={(step, isNotCustom = false) => handleStepChange(step, isNotCustom)}
            savedValues={values && values.step2 ? values.step2 : null}
            packageList={props.createCandConfig.packages || []}
            handleSelectedSubtypeId={handleSelectedSubtypeId}
            selectedPackageId={selectedPackageId}
            selectedPackage={selectedPackage}
            isBulkUpload={isBulkUpload}
            bulkCandidates={tableData?.length}
            history={props?.history}
            goBackToBulkUpload={() => {
              props?.finalData?.setCurrentStep(2);
            }}
          />
        );
      case 3:
        return (
          <getCandidateConfigContext.Provider value={props?.getAddCandidateConfig}>
            <SelectedPackage
              handleStepSubmit={(type, formValues, nextStep) =>
                handleStepSubmit(type, formValues, nextStep)
              }
              handleStepChange={(step) => handleStepChange(step)}
              savedValues={values && values.step3 ? values.step3 : null}
              customizeValues={
                values && values.step2 && values.step2.customise ? values.step2.customise : null
              }
              step1Values={values && values.step1 ? values.step1 : null}
              pDetails={
                props.createCandConfig && props.createCandConfig.packages
                  ? {
                      ...selectedPackage,
                      isCustomisable: isCustomisable(selectedPackage.config)
                    }
                  : null
              }
              initialValues={Step3Values}
              setPaymentStatus={(value) => {
                // is payment is successfull call submit funtion to add candidate
                setPaymentStatus(value);
                if (value === 'completed') {
                  startSubmit();
                }
              }}
              isSubmitting={isSubmitting}
              isBulkUpload={isBulkUpload}
              bulkCandidates={tableData?.length}
            />
          </getCandidateConfigContext.Provider>
        );
      case 4:
        return (
          <PaymentResults
            paymentStatus={paymentStatus}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setPaymentStatus={setPaymentStatus}
            description={
              paymentStatus == 'failed' && (
                <p>Something went wrong. Please try again in a few minutes.</p>
              )
            }
            isBulkUpload={isBulkUpload}
          />
        );
      case 5:
        return <AccessRestricted setCurrentStep={setCurrentStep} />;
      default:
        return (
          <BasicDetails
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            uploadState={uploadState}
            setUploadState={setUploadState}
            file={file}
            setFile={setFile}
            handleStepChange={(step) => handleStepChange(step)}
            savedValues={values && values.step1 ? values.step1 : null}
          />
        );
    }
  };

  const getStepHeading = (step) => {
    switch (step) {
      case 1:
        return 'Add Candidate Details';
      case 2:
        return 'Choose a verification package';
      case 3:
        return 'Customize verification';
      default:
        return 'Add Candidate';
    }
  };

  const stepShow = ['Add Details', 'Choose Package', 'Customize and Confirm'];

  return (
    <div className={styles.candWrapperWrapper}>
      <div className={styles.subHeading}>
        <h4 className={styles.header}>Create Candidate</h4>
        {currentStep === 1 && (
          <Button
            className={styles.addinBulk}
            onClick={() => {
              window.open('/bulkCandidate', '_blank');
            }}
          >
            + Add Candidates in Bulk
          </Button>
        )}
      </div>
      <div className={styles.cardSection}>
        {!loading ? (
          <Card
            className={`${styles.addCandidateCard} ${
              paymentStatus === 'failed' ? styles.failedCard : ''
            } ${props?.finalData?.isBulkUpload ? styles.bulkUploadCard : ''}`}
            border={props?.finalData?.isBulkUpload ? '' : 'light'}
          >
            <div className={styles.headerx}>
              <div className={styles.left} style={{ display: 'flex' }}>
                {getStepHeading(currentStep)}
              </div>
              <div className={styles.right}>
                {stepShow.map((step, index) => (
                  <>
                    <div
                      key={index}
                      className={styles.step}
                      style={{
                        color:
                          index < currentStep - 1
                            ? '#8d8d8d'
                            : index === currentStep - 1
                            ? 'black'
                            : '#8d8d8d'
                      }}
                    >
                      {step}
                    </div>
                    {index < stepShow.length - 1 && (
                      <div
                        className={styles.spacer}
                        style={{
                          color: index < currentStep - 1 ? '#8d8d8d' : '#8d8d8d'
                        }}
                      >
                        {'>'}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
            {getFormBasedOnStep(currentStep)}
          </Card>
        ) : (
          <Card
            className={`${styles.loadingCard} ${
              props?.finalData?.isBulkUpload ? styles.bulkUploadCard : ''
            }`}
            border={props?.finalData?.isBulkUpload ? '' : 'light'}
          >
            <Loader className={styles.loader} />
          </Card>
        )}
      </div>
      {showConnectModal && optionsForConnection?.length ? (
        <ConnectModal
          list={optionsForConnection}
          show={showConnectModal}
          onHide={() => setShowConnectModal(false)}
        />
      ) : null}
    </div>
  );
};
