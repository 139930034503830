// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EuEGqcTWkHLYhwGPxP9w {
  max-width: 1072px;
  margin: auto;
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .EuEGqcTWkHLYhwGPxP9w {
    width: 1200px;
  }
}
.EuEGqcTWkHLYhwGPxP9w .Xip6Wa08iakqWq6c4wQj {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.EuEGqcTWkHLYhwGPxP9w .Xip6Wa08iakqWq6c4wQj .wdIWtl8Wj2ZdD7rH44Ne {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 15px;
}
.EuEGqcTWkHLYhwGPxP9w .AftOXSLrRMM4OBZjBwvQ {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 20px 22px;
  font-family: Poppins, sans-serif;
  margin-bottom: 20px;
}
.EuEGqcTWkHLYhwGPxP9w .AftOXSLrRMM4OBZjBwvQ .sEmD8BblJV2bS4ziMIRN {
  height: 296px;
  width: 100%;
  background: linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%);
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ReferAndEarn/ReferAndEarn.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;EACA,kBAAA;AACF;AAAE;EAJF;IAKI,aAAA;EAGF;AACF;AAFE;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,gCAAA;AAIJ;AAHI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAKN;AAFE;EACE,mBAAA;EACA,2CAAA;EACA,kBAAA;EACA,kBAAA;EACA,gCAAA;EACA,mBAAA;AAIJ;AAHI;EACE,aAAA;EACA,WAAA;EACA,+EAAA;AAKN","sourcesContent":[".referralWrapper {\n  max-width: 1072px;\n  margin: auto;\n  position: relative;\n  @media only screen and (max-width: 1200px) {\n    width: 1200px;\n  }\n  .header {\n    display: flex;\n    justify-content: space-between;\n    margin-top: -190px;\n    font-family: Poppins, sans-serif;\n    .heading {\n      font-weight: 500;\n      font-size: 24px;\n      line-height: 36px;\n      color: #ffffff;\n      margin-bottom: 15px;\n    }\n  }\n  .referCard {\n    background: #ffffff;\n    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);\n    border-radius: 4px;\n    padding: 20px 22px;\n    font-family: Poppins, sans-serif;\n    margin-bottom: 20px;\n    .cardContainer {\n      height: 296px;\n      width: 100%;\n      background: linear-gradient(to right, #0844a6 0%, #086590 48.96%, #088077 100%);\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referralWrapper": `EuEGqcTWkHLYhwGPxP9w`,
	"header": `Xip6Wa08iakqWq6c4wQj`,
	"heading": `wdIWtl8Wj2ZdD7rH44Ne`,
	"referCard": `AftOXSLrRMM4OBZjBwvQ`,
	"cardContainer": `sEmD8BblJV2bS4ziMIRN`
};
export default ___CSS_LOADER_EXPORT___;
