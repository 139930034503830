// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F9RgD1oqXfWkZmBk5h6L {
  font-family: Poppins;
  padding-top: 20px;
  margin-bottom: 40px;
}
.F9RgD1oqXfWkZmBk5h6L .jbTC8OifPedkvt0UaOUa {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.F9RgD1oqXfWkZmBk5h6L .k8CqogLqA66si8wgoyEV {
  max-height: 800px;
  overflow-y: auto;
  padding: 20px 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.F9RgD1oqXfWkZmBk5h6L .k8CqogLqA66si8wgoyEV .TiVmWF_9aXRBx9BIjBRe {
  color: #2755fe;
  margin-bottom: 20px;
  width: 100px;
}
.F9RgD1oqXfWkZmBk5h6L .PhEfOO9uKgF_NQMHIsZ0 {
  overflow-y: visible !important;
  margin-top: 50px;
}

.q7mwF1zA4Dj9UjBIIQvY {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: inherit;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v {
  text-align: center;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v h2 {
  font-weight: normal;
  font-size: 24px;
  color: #333333;
  margin-top: 24px;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v p {
  font-weight: normal;
  font-size: 14px;
  color: #666666;
  margin-top: 8px;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v p a {
  font-weight: bold;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v button {
  margin-top: 24px;
  background: #2755fe;
  border-radius: 2px;
}
.q7mwF1zA4Dj9UjBIIQvY .a3Z1kZzM8vrJISOtp52v button a {
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/CreateCandidate/PackageListing/PackageListing.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,iBAAA;EACA,mBAAA;AACF;AAAE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAEJ;AAAE;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AADI;EACE,cAAA;EACA,mBAAA;EACA,YAAA;AAGN;AAAE;EACE,8BAAA;EACA,gBAAA;AAEJ;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;AAEF;AADE;EACE,kBAAA;AAGJ;AAFI;EACE,mBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AAIN;AAFI;EACE,mBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AAIN;AAHM;EACE,iBAAA;AAKR;AAFI;EACE,gBAAA;EACA,mBAAA;EACA,kBAAA;AAIN;AAHM;EACE,cAAA;AAKR","sourcesContent":[".baseGrid {\n  font-family: Poppins;\n  padding-top: 20px;\n  margin-bottom: 40px;\n  .heading {\n    font-size: 32px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 48px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #333333;\n  }\n  .packageListContainer {\n    max-height: 800px;\n    overflow-y: auto;\n    padding: 20px 70px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    .goBackBtn {\n      color: #2755fe;\n      margin-bottom: 20px;\n      width: 100px\n    }\n  }\n  .bulkUploadPackageListing {\n    overflow-y: visible !important;\n    margin-top: 50px;\n  }\n}\n.noPackageFound {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  min-height: inherit;\n  .textContainer {\n    text-align: center;\n    h2 {\n      font-weight: normal;\n      font-size: 24px;\n      color: #333333;\n      margin-top: 24px;\n    }\n    p {\n      font-weight: normal;\n      font-size: 14px;\n      color: #666666;\n      margin-top: 8px;\n      a {\n        font-weight: bold;\n      }\n    }\n    button {\n      margin-top: 24px;\n      background: #2755fe;\n      border-radius: 2px;\n      a {\n        color: #ffffff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseGrid": `F9RgD1oqXfWkZmBk5h6L`,
	"heading": `jbTC8OifPedkvt0UaOUa`,
	"packageListContainer": `k8CqogLqA66si8wgoyEV`,
	"goBackBtn": `TiVmWF_9aXRBx9BIjBRe`,
	"bulkUploadPackageListing": `PhEfOO9uKgF_NQMHIsZ0`,
	"noPackageFound": `q7mwF1zA4Dj9UjBIIQvY`,
	"textContainer": `a3Z1kZzM8vrJISOtp52v`
};
export default ___CSS_LOADER_EXPORT___;
