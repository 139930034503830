import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Badge, Container, Form, Button } from 'react-bootstrap';
import { has, isEmpty } from 'lodash';

import AddressModal from './AddressModals';
import BgvSecureText from '../../../CommonComponent/BgvSecureText/BgvSecureText';
import BgvGoBackBtn from '../../../CommonComponent/BgvGoBackBtn/BgvGoBackBtn';
import BGVConfirmDialog from '../../../CommonComponent/BGVConfirmDialog';
import ProgressBar from '../../../../../core-components/ProgressBar';
import Image from '../../../../../core-components/Image';
import logger from '../../../../../core-components/Logger';
import { toSnakeCase } from '../../../../../utils/utilities';
import {
  ADDRESS_LOOPING_CONSTANT,
  AddressArr,
  AddressArrLabelObj
} from '../../../../../utils/commonConstant';

import styles from './Address.module.scss';

function getAddressVerificationText(config) {
  const { bgv_form_config, add_on_config } = config;

  // Extracting values from bgv_form_config and add_on_config
  const addressChecks = [...(bgv_form_config?.address || []), ...(add_on_config?.address || [])];
  const courtChecks = [...(bgv_form_config?.court || []), ...(add_on_config?.court || [])];

  // fnction to check for specific verification types
  const hasVerificationType = (checks, type) =>
    checks.some((check) => check.verification_type === type);

  const hasDAV = hasVerificationType(addressChecks, 'DIGITAL');
  const hasPAV = hasVerificationType(addressChecks, 'PHYSICAL');
  const hasPOSTAL = hasVerificationType(addressChecks, 'POSTAL');
  const hasCourt = courtChecks.length > 0;

  // ADD TYPE = only DAV
  if (hasDAV && !hasPAV && !hasPOSTAL) {
    return "For your address verification, you'll receive a link to verify your address and upload the address-proof documents. Ensure you or someone related to you is present at the address below to assist in verification.";
  }

  // ADD TYPE = only PAV, or PAV+DAV
  if (hasPAV && !hasPOSTAL) {
    return 'For your address verification, a field agent will be visiting you over the next seven days to complete the verification. Ensure you or someone related to you is present at the address below to assist in verification.';
  }

  // ADD TYPE = at least 1 POSTAL
  if (hasPOSTAL) {
    return "Please provide the address where the address verification can be conducted. Ensure that the address you provide matches the address type and the address proof documents that you'll be uploading.";
  }

  // Court check is part of the package but Address is not part of the package and ADD is shown in BGV
  if (hasCourt && addressChecks.length === 0) {
    return "Ensure that the address you provide matches the address type and the address proof documents that you'll be uploading.";
  }

  return '';
}

export default (props) => {
  const [modalSequenceId] = useState([1, 2, 3]);
  const [modalCurrentSequenceId, setModalCurrentSequenceId] = useState(0);
  const [subStep, setSubStep] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('');
  const [courtOnly, setCourtOnly] = useState(false);
  const [currentSamePermanent, setCurrentSamePermanent] = useState(
    has(props.cachedData, 'currentPermanentSame') && props.cachedData.currentPermanentSame
      ? props.cachedData.currentPermanentSame
      : false
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showFinalSubmit] = useState(props.finalSubmitCheck === 3);
  const [renderArray, setRenderArray] = useState([]);

  // const [address, setAddress] = useState(props.bgvConfig.config.address);

  // const [court, setCourt] = useState(props.bgvConfig.config.court);

  const [currentAddressIndex, setCurrentAddressIndex] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [showRemove, setShowRemove] = useState(0);
  const [addressType, setAddressType] = useState(null);
  const [requiredAddress, setRequiredAddress] = useState([]);

  const [addressSkipEnable, setAddressSkip] = useState(
    has(props.cachedData, 'addressSkipEnable') && props.cachedData.addressSkipEnable
      ? props.cachedData.addressSkipEnable
      : false
  );
  const [skippable, setSkippable] = useState(false);
  const [allAddressPresent, setAllAddressPresent] = useState(null);

  const checkConfig = JSON.parse(JSON.stringify(props.bgvConfig));
  const address = checkConfig.config.address || [];

  const court = checkConfig.config.court || [];

  useEffect(() => {
    const loggerObj = {
      message: 'Address Main Page',
      data: JSON.stringify(props.cachedData),
      category: `candidate-id-${props.candidateId}`,
      subCategory: 'Address',
      type: 'info'
    };
    logger.push(loggerObj);

    formAddressRenderArray(address, court);
    setCourtOnly(address.length ? false : true);
  }, []);

  // permanent address and current address same
  useEffect(() => {
    if (
      renderArray.length > 1 &&
      has(props.cachedData, 'cacheData') &&
      !isEmpty(props.cachedData.cacheData) &&
      has(props.cachedData.cacheData, 'addressCheck') &&
      props?.cachedData?.cacheData?.addressCheck?.length &&
      !isEmpty(props.cachedData.cacheData.addressCheck[0]) &&
      has(props.cachedData.cacheData.addressCheck[0], 'step3')
    ) {
      if (props.cachedData?.cacheData?.addressCheck[0]?.step2?.isCurrentPermanentSame) {
        setCurrentSamePermanent(true);
      } else {
        setCurrentSamePermanent(false);
      }
    }
  }, [props.cachedData]);

  useEffect(() => {
    if (currentSamePermanent) {
      let finalCacheData = {};
      let addressCheck = [...props.cachedData.cacheData.addressCheck];
      let permanentAddressCopy = JSON.parse(JSON.stringify(addressCheck[0]));
      addressCheck[1] = permanentAddressCopy;
      addressCheck[1].step1.addressType = 'CURRENT';
      const saveObject = toSnakeCase({
        ...props.cachedData,
        cacheData: {
          ...props.cachedData.cacheData,
          addressCheck: [...addressCheck]
        },
        currentPermanentSame: true,
        currentState: '3#2'
      });

      finalCacheData = {
        saveObject
      };
      // call api
      props.setBgvCache(finalCacheData).then((res) => {
        logger.push({
          message: 'Permanent and Current Address same ',
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'All 3 steps inserted',
          type: 'info'
        });
        // props.nextSubStep();
      });
    }
  }, [currentSamePermanent]);

  // This is for substep of current selected address check
  useEffect(() => {
    if (
      has(props.cachedData.cacheData, 'addressCheck') &&
      props.cachedData.cacheData.addressCheck.length > currentAddressIndex &&
      !isEmpty(props.cachedData.cacheData.addressCheck[currentAddressIndex]) &&
      has(props.cachedData.cacheData.addressCheck[currentAddressIndex], 'currentStep')
    ) {
      setSubStep(props.cachedData.cacheData.addressCheck[currentAddressIndex].currentStep);
      setModalCurrentSequenceId(
        modalSequenceId.indexOf(
          props.cachedData.cacheData.addressCheck[currentAddressIndex].currentStep
        )
      );
    } else {
      setSubStep(1);
    }
  }, [currentAddressIndex]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  //To find if ALL address are present for
  const ifAllPresent = () => {
    let allAddress = true;
    for (let i = 0; i < renderArray.length; i++) {
      if (
        isEmpty(props.cachedData.cacheData.addressCheck[i]) ||
        (!isEmpty(props.cachedData.cacheData.addressCheck[i]) &&
          ((!has(props.cachedData.cacheData.addressCheck[i], 'step1') &&
            isEmpty(props.cachedData.cacheData.addressCheck[i].step1)) ||
            (!has(props.cachedData.cacheData.addressCheck[i], 'step2') &&
              isEmpty(props.cachedData.cacheData.addressCheck[i].step2)) ||
            (!has(props.cachedData.cacheData.addressCheck[i], 'step3') &&
              isEmpty(props.cachedData.cacheData.addressCheck[i].step3))))
      ) {
        return false;
      }
    }
    return allAddress;
  };

  useEffect(() => {
    if (
      has(props.cachedData, 'cacheData') &&
      !isEmpty(props.cachedData.cacheData) &&
      has(props.cachedData.cacheData, 'addressCheck') &&
      !isEmpty(props?.cachedData?.cacheData?.addressCheck)
    ) {
      setAllAddressPresent(ifAllPresent());
    } else {
      setAllAddressPresent(false);
    }
  }, [renderArray, props.cachedData]);

  useEffect(() => {
    let extraRequiredAddress = [];
    const addressCheckLabelsPresent =
      props?.cachedData?.cacheData?.addressCheck
        ?.filter((check) => !isEmpty(check))
        .map((check) => check?.step1?.addressType) || [];
    const addressArr =
      addressCheckLabelsPresent.length > 0
        ? addressCheckLabelsPresent.map((label) => AddressArrLabelObj[label])
        : [];
    extraRequiredAddress = ADDRESS_LOOPING_CONSTANT.filter(
      (item) =>
        item.dataName !== 'ANY ADDRESS' &&
        !item.dataName.includes(addressArr) &&
        item.dataName.includes(court)
    );
    // extraRequiredAddress address
    setRequiredAddress(extraRequiredAddress);
  }, [props?.cachedData?.cacheData?.addressCheck]);

  useEffect(() => {
    if (address.length === 1 && court.length === 1 && address[0] === 'ANY_1') {
      if (requiredAddress.length > 0) {
        setRenderArray([ADDRESS_LOOPING_CONSTANT[2], ...requiredAddress]);
      } else {
        setRenderArray([ADDRESS_LOOPING_CONSTANT[2]]);
      }
    }
  }, [requiredAddress]);
  // sprint-10 changes form new array combining court and address
  const formAddressRenderArray = (address, court) => {
    let tempAddress = [];
    if (address?.length > 1 || court?.length > 1) {
      tempAddress = getAllAddress();
    }
    // both has count 1
    else if (address.length && court.length) {
      // both are same
      if (address[0] === court[0]) {
        if (address[0] === 'PERMANENT') {
          tempAddress.push(ADDRESS_LOOPING_CONSTANT[0]);
        } else if (address[0] === 'CURRENT') {
          tempAddress.push(ADDRESS_LOOPING_CONSTANT[1]);
        } else {
          tempAddress.push(ADDRESS_LOOPING_CONSTANT[2]);
        }
      } else if (address[0].includes('ANY_1')) {
        tempAddress.push(ADDRESS_LOOPING_CONSTANT[2]);
      } else if (court[0].includes('ANY_1')) {
        if (address[0] === 'PERMANENT') {
          tempAddress.push(ADDRESS_LOOPING_CONSTANT[0]);
        } else {
          tempAddress.push(ADDRESS_LOOPING_CONSTANT[1]);
        }
      } else {
        tempAddress = getAllAddress();
      }
    }
    // only address present
    else if (address?.length) {
      if (address[0] === 'PERMANENT') {
        tempAddress.push(ADDRESS_LOOPING_CONSTANT[0]);
      } else if (address[0] === 'CURRENT') {
        tempAddress.push(ADDRESS_LOOPING_CONSTANT[1]);
      } else {
        tempAddress.push(ADDRESS_LOOPING_CONSTANT[2]);
      }
    }
    //  only court present
    else if (court?.length) {
      if (court[0] === 'PERMANENT') {
        tempAddress.push(ADDRESS_LOOPING_CONSTANT[0]);
      } else if (court[0] === 'CURRENT') {
        tempAddress.push(ADDRESS_LOOPING_CONSTANT[1]);
      } else {
        tempAddress.push(ADDRESS_LOOPING_CONSTANT[2]);
      }
    }
    setRenderArray(tempAddress);
  };
  const getAllAddress = () => {
    let tempArray = [];
    tempArray.push(ADDRESS_LOOPING_CONSTANT[0]);
    tempArray.push(ADDRESS_LOOPING_CONSTANT[1]);
    setRenderArray(tempArray);
    return tempArray;
  };
  const refeshSubStep = () => {
    setSubStep(1);
    setModalCurrentSequenceId(modalSequenceId.indexOf(subStep));
  };

  const nextSubStep = () => {
    if (modalCurrentSequenceId < modalSequenceId.length) {
      setSubStep(subStep + 1);
      setModalCurrentSequenceId(modalSequenceId.indexOf(subStep + 1));
    }
  };

  const prevSubStep = () => {
    if (modalCurrentSequenceId > 0) {
      setSubStep(subStep - 1);
      setModalCurrentSequenceId(modalSequenceId.indexOf(subStep));
    }
  };
  const handleAddressDelete = () => {
    let cacheData = { ...props.cachedData.cacheData };
    let saveObject;
    let deleteBoth = false;
    if (
      renderArray.length > 1 &&
      deleteIndex === 0 &&
      has(props.cachedData, 'cacheData') &&
      !isEmpty(props.cachedData.cacheData) &&
      has(props.cachedData.cacheData, 'addressCheck') &&
      props?.cachedData?.cacheData?.addressCheck?.length &&
      !isEmpty(props.cachedData.cacheData.addressCheck[0]) &&
      props?.cachedData?.cacheData?.addressCheck[0]?.step2?.isCurrentPermanentSame
    ) {
      cacheData.addressCheck.splice(deleteIndex, 2);
      deleteBoth = true;
      saveObject = toSnakeCase({
        ...props.cachedData,
        cacheData: { ...cacheData },
        currentState: '3',
        currentPermanentSame: false,
        addressSkipEnable: false
      });
    } else {
      if (deleteIndex > 2) {
        setAddressSkip(false);
      }

      // Delete the details from cache only for selected check(permanent add is stored at index 0 always and so on.)
      if (address?.length > 0 && address[0] === 'ANY_1') {
        cacheData.addressCheck.splice(deleteIndex, 1);
      } else {
        cacheData.addressCheck[deleteIndex] = {};
      }

      saveObject = toSnakeCase({
        ...props.cachedData,
        cacheData: { ...cacheData },
        currentState: '3',
        addressSkipEnable: deleteIndex < 2 ? false : addressSkipEnable
      });
    }

    const finalCacheData = {
      saveObject
    };
    props
      .setBgvCache(finalCacheData)
      .then((response) => {
        // logger code
        if (deleteBoth) {
          setAddressSkip(false);
          setCurrentSamePermanent(false);
        }
        const loggerObj = {
          message: ` Address is deleted`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Address Main page',
          type: 'info'
        };
        logger.push(loggerObj);
      })
      .catch((error) => {
        console.error(error);
        // logger code
        const loggerObj = {
          message: ` Address is not deleted`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Address Main Page',
          type: 'error'
        };
        logger.push(loggerObj);
      });
  };

  //invalidate temporary store
  const setModal = () => {
    props.invalidateAddressDocType();
    props.invalidateFrontDoc();
    props.invalidateBackDoc();
    props.invalidateSameDoc();
  };

  const handleConfirmSubmit = (childProps) => {
    childProps.handleSubmit();
  };

  const handleConfirmModal = () => {
    props.setSubmitting(false);
    setShowConfirmation(!showConfirmation);
  };

  const handleSkip = () => {
    setAddressSkip(!addressSkipEnable);

    let cacheData = { ...props.cachedData.cacheData };

    let saveObject = toSnakeCase({
      ...props.cachedData,
      cacheData: { ...cacheData },
      currentState: '3',
      addressSkipEnable: !addressSkipEnable
    });

    const finalCacheData = {
      saveObject
    };

    props
      .setBgvCache(finalCacheData)
      .then((response) => {
        // logger code
        const loggerObj = {
          message: ` Address is Skipped`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Address Main page',
          type: 'info'
        };
        logger.push(loggerObj);
      })
      .catch((error) => {
        console.error(error);
        // logger code
        const loggerObj = {
          message: ` Address is not skipped`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Address Main Page',
          type: 'error'
        };
        logger.push(loggerObj);
      });
  };
  const addAddressCard = (checkData, index) => {
    return (
      <Container
        className={styles.addAddressCard}
        style={{ marginTop: checkData.dataName === 'OTHER ADDRESS' ? '5px' : null }}
        key={index}
      >
        <Row>
          <Col className={styles.addressCheckName}>
            {checkData.label}{' '}
            {checkData.dataName !== 'OTHER ADDRESS' ? (
              <span className={styles.required}>*</span>
            ) : null}
            {checkData.dataName === 'OTHER ADDRESS' ? (
              <div className={styles.otherTag}> Address that you've lived at previously</div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className={styles.addBtn}
              onClick={() => {
                setAddressType(checkData.dataName);
                setCurrentAddressIndex(index);
                setModalShow(true);
                setModal();
                setTitle('Add');
              }}
            >
              <Image className={styles.addSign} name='AddId.svg' />
              Add Address
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  const addressInfoCard = (data, check, index) => {
    return (
      <Container className={styles.idInfoCard} key={index}>
        <Row>
          <Col md={7} sm={7} xs={7} lg={7}>
            <div className={styles.checkName}>
              <div>{AddressArr[data.step1.addressType]}</div>

              {check.dataName === 'OTHER ADDRESS' ? (
                <div className={styles.otherTag}> Address that you've lived at previously</div>
              ) : null}
            </div>

            <div className={styles.checkInfo}>{data?.step1?.colony}</div>
          </Col>
          {check.dataName === 'CURRENT ADDRESS' && currentSamePermanent ? (
            <Col md={5} sm={5} xs={5} lg={5}>
              <div className={styles.currentTag}> Marked same as permanent address </div>
            </Col>
          ) : (
            <>
              <Col md={3} sm={2} xs={1} lg={3}></Col>
              <Col md={1} sm={1} xs={1} lg={1}>
                <Image
                  style={{ marginRight: '21px', cursor: 'pointer' }}
                  name='BgvIdEdit.svg'
                  onClick={() => {
                    setCurrentAddressIndex(index);
                    setModalShow(true);
                    refeshSubStep();
                    setTitle('Edit');
                    setAddressType(check.dataName);
                  }}
                />
              </Col>
              <Col md={1} sm={1} xs={2} lg={1}>
                <Image
                  name='BgvIdDelete.svg'
                  style={{
                    display: showRemove && index === deleteIndex && 'none',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setShowRemove(!showRemove);
                    setDeleteIndex(index);
                  }}
                />
                {showRemove && index === deleteIndex ? (
                  <Badge
                    className={styles.badge}
                    onClick={() => {
                      setShowRemove(false);
                      handleAddressDelete();
                    }}
                    pill
                    variant='danger'
                  >
                    Delete ?
                  </Badge>
                ) : null}
              </Col>{' '}
            </>
          )}
        </Row>
      </Container>
    );
  };

  const isValidForm = allAddressPresent || addressSkipEnable;

  const indexing =
    props.totalSteps
      .map((val) => {
        return val?.id;
      })
      .indexOf(3) + 1;
  return (
    <Card className={styles.addressCard}>
      <Card.Body className={styles.addressBody}>
        <BgvSecureText />
        <div className={styles.addressHeading}>Address</div>
        <div className={styles.progressBar}>
          <span className={styles.label}>
            {indexing} of {props.totalSteps.length}
          </span>
          <ProgressBar
            className={styles.Mt10}
            max={props.totalSteps.length}
            min={0}
            now={indexing}
          />
        </div>
        <p className={styles.heading}>Add your address here</p>
        <p className={styles.info}>
          {getAddressVerificationText(checkConfig?.config?.address_court_verification_type)}
        </p>

        {/* ------- SPRINT-10 changes ----- */}
        {renderArray.map((check, index) => {
          if (
            has(props.cachedData.cacheData, 'addressCheck') &&
            !isEmpty(props.cachedData.cacheData.addressCheck[index]) &&
            has(props.cachedData.cacheData.addressCheck[index], 'step3')
          ) {
            return addressInfoCard(props.cachedData.cacheData.addressCheck[index], check, index);
          } else {
            return addAddressCard(check, index);
          }
        })}
      </Card.Body>
      <Card.Footer className={styles.addressFooter}>
        <Row>
          {renderArray.length > 2 ? (
            <Col sm={12} xs={12} md={12} lg={12} className={styles.skipBtn}>
              <Form.Check
                checked={addressSkipEnable}
                disabled={!skippable}
                type={'checkbox'}
                id='identitySkipEnable'
                name='identitySkipEnable'
                label={`I don't have any further details than what is provided`}
                onChange={handleSkip}
              />
            </Col>
          ) : null}

          <Col sm={12} xs={12} md={12} lg={12} className={styles.addressSubmitBtn}>
            {/* Added common prev button */}
            <BgvGoBackBtn handleClick={props.prevStep} />
            <Button
              className={
                isValidForm && !props.internalLoading ? styles.activeBtn : styles.disabledBtn
              }
              disabled={!isValidForm || props.isLoadingBgvCache || props.internalLoading}
              onClick={() => {
                showFinalSubmit ? setShowConfirmation(true) : props.handleSubmit();
              }}
            >
              {showFinalSubmit ? 'Submit' : 'Next'}
            </Button>
          </Col>
        </Row>
      </Card.Footer>
      {/* Confirm Dialog */}
      {showFinalSubmit && showConfirmation && (
        <BGVConfirmDialog
          showConfirmation={showConfirmation}
          handleConfirmSubmit={handleConfirmSubmit}
          handleHide={handleConfirmModal}
          enableBtn={isValidForm}
          {...props}
        />
      )}
      {modalShow ? (
        <AddressModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          canidateId={props.candidateId}
          title={title}
          index={currentAddressIndex}
          addressType={addressType} // not required
          currentModalSubStep={subStep}
          nextSubStep={nextSubStep}
          refresh={refeshSubStep}
          prevSubStep={prevSubStep}
          totalStep={modalSequenceId.length}
          configuration={props.config.current_address_required}
          {...props}
        />
      ) : null}
    </Card>
  );
};
